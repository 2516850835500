import {
    getPago,
    changePagoStatus,
    getPagos,
    storePago,
    updatePago,
    deletePago
} from "./pagos.actions";
import {
    deletePagoService,
    getPagoService,
    getPagosService,
    storePagoService,
    updatePagoService
} from "../../services/pago.service";

export const getPagosThunk = (id) => {
    return async (dispatch) => {
        dispatch(changePagoStatus('loading'));
        try {
            const data = await getPagosService(id);
            console.log("data pagos", data);
            if (!data.error) {
                dispatch(getPagos(data));
                dispatch(changePagoStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const getPagoThunk = (id) => {
    return async (dispatch) => {
        dispatch(changePagoStatus('loading'));
        try {
            const data = await getPagoService(id);
            console.log("data pago", data);
            if (!data.error) {
                dispatch(getPago(data));
                dispatch(changePagoStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const storePagoThunk = (obj) => {
    return async (dispatch) => {
        dispatch(changePagoStatus('loading'));
        try {
            const data = await storePagoService(obj);
            console.log("data pagos", data);
            if (!data.error) {
                dispatch(storePago(data));
                dispatch(changePagoStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const updatePagoThunk = (obj, id) => {
    return async (dispatch) => {
        dispatch(changePagoStatus('loading'));
        try {
            const data = await updatePagoService(obj, id);
            console.log("data edit", data);
            if (!data.error) {
                dispatch(updatePago(data));
                dispatch(changePagoStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const deletePagoThunk = (id) => {
    return async (dispatch) => {
        dispatch(changePagoStatus('loading'));
        try {
            const data = await deletePagoService(id);
            console.log("data delete pago", data);
            if (!data.error) {
                dispatch(deletePago(data));
                dispatch(changePagoStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};
