import VentaActionTypes from "./ventas.types";

//ACTION CREATORS

export const changeVentaStatus = (payload) => ({
    type: VentaActionTypes.CHANGE_STATUS_VENTA,
    payload,
});

export const getVentas = (payload) => ({
    type: VentaActionTypes.GET_VENTAS,
    payload,
});

export const getVenta = (payload) => ({
    type: VentaActionTypes.GET_VENTA,
    payload,
});

export const storeVenta = (payload) => ({
    type: VentaActionTypes.STORE_VENTA,
    payload,
});

export const updateVenta = (payload) => ({
    type: VentaActionTypes.UPDATE_VENTA,
    payload,
});

export const deleteVenta = (payload) => ({
    type: VentaActionTypes.DELETE_VENTA,
    payload,
});
