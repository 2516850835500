const VentaActionTypes = {
  CHANGE_STATUS_VENTA: "CHANGE_STATUS_VENTA",
  GET_VENTAS: "GET_VENTAS",
  GET_VENTA: "GET_VENTA",
  STORE_VENTA: "STORE_VENTA",
  UPDATE_VENTA: "UPDATE_VENTA",
  DELETE_VENTA: "DELETE_VENTA",
};

export default VentaActionTypes;
