import MarcaActionTypes from "./marcas.types";

//ACTION CREATORS

export const changeMarcaStatus = (payload) => ({
    type: MarcaActionTypes.CHANGE_STATUS_MARCA,
    payload,
});

export const getMarcas = (payload) => ({
    type: MarcaActionTypes.GET_MARCAS,
    payload,
});

export const getMarca = (payload) => ({
    type: MarcaActionTypes.GET_MARCA,
    payload,
});

export const storeMarca = (payload) => ({
    type: MarcaActionTypes.STORE_MARCA,
    payload,
});

export const updateMarca = (payload) => ({
    type: MarcaActionTypes.UPDATE_MARCA,
    payload,
});

export const deleteMarca = (payload) => ({
    type: MarcaActionTypes.DELETE_MARCA,
    payload,
});
