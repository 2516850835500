const MedidaActionTypes = {
  CHANGE_STATUS_MEDIDA: "CHANGE_STATUS_MEDIDA",
  GET_MEDIDAS: "GET_MEDIDAS",
  GET_MEDIDA: "GET_MEDIDA",
  STORE_MEDIDA: "STORE_MEDIDA",
  UPDATE_MEDIDA: "UPDATE_MEDIDA",
  DELETE_MEDIDA: "DELETE_MEDIDA",
};

export default MedidaActionTypes;
