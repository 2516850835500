import {Avatar, Card, CardActions, CardContent, CardHeader, IconButton, Tooltip, Typography} from "@mui/material";
import {
    AccountBalance,
    AddCircle, Article,
    CurrencyExchange,
    DesignServices, Edit,
    Image, Money,
    MoreVert,
    Paid, PostAdd, PriceCheck, ShoppingCartCheckout, ShoppingCartSharp,
    Tag
} from "@mui/icons-material";
import {useState} from "react";

import {loadFile} from "../../../helpers/files";
import {addProductoPrint, addProductoSale} from "../../../redux/productos/productos.actions";
import {connect} from "react-redux";
import {toastr} from "react-redux-toastr";
import moment from "moment";
import {getVentasThunk} from "../../../redux/ventas/ventas.thunk";
import {getVenta} from "../../../redux/ventas/ventas.actions";
import {getCliente} from "../../../redux/clientes/clientes.actions";
import {useNavigate} from "react-router-dom";
import {getProductosByIdsService} from  "../../../services/venta.service"

const VentaItem = (props) => {
    const navigate = useNavigate();
    const {item} = props;
    const [openPrecio, setOpenPrecio] = useState(false);
    const [openPhoto, setOpenPhoto] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const onAddPrecio = () => {
        setOpenPrecio(true);
    }

    const onClosePrecio = async () => {
        setOpenPrecio(false);
    }

    const onPushPrint = () => {
        if(!props.productosPrint.find((x) => { return x.producto.id === item.id})) {
            props.addProductoPrint([...props.productosPrint, {
                producto: item
            }]);
            toastr.success('Agregado exitosamente para imprimir')
        } else {
            toastr.warning('Ya esta agregado')
        }
    }

    const onEditVenta = async () => {
        const ids = item.productos.map(item => {
            return item.producto_id
        })
        const productsInfo = await getProductosByIdsService(ids)
        console.log("INFOO", productsInfo)
        item.productos = item.productos.map(producto=> {
            productsInfo.map(prodInfo => {
                if (prodInfo.id === producto.producto_id) {
                    producto.producto = prodInfo
                }
            })
            return producto;
        })

        props.printNota(item);
        props.editProductos(item.productos);
        props.getCliente(item.cliente);
        navigate('/sales-create')
    }

    return (
        <Card className="h-100 position-relative" sx={{
            minHeight: '100%',
            cursor: 'pointer',
            borderRadius: '15px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        }}>
            <CardHeader
                action={
                    <IconButton aria-label="settings">
                        <MoreVert/>
                    </IconButton>
                }
                title={'Fecha: ' + moment(item.created_at).format('LLL')}
                subheader={'Cliente: ' +  (item.cliente?.nombre?.toUpperCase() || 'NINGUNO')}/>
            <CardContent>
                <Typography variant="body2" color="text.secondary" className="d-flex justify-content-between my-3 text-description">
                    <div className="table-responsive" style={{maxHeight: '200px'}}>
                        <table className="table">
                            <thead>
                            <tr>
                                <th className="">#</th>
                                <th className="">PRODUCTO</th>
                                <th className="">P.U.</th>
                                <th className="">SUB.</th>
                            </tr>
                            </thead>
                            <tbody>
                            {item.productos.map((i, ind) =>
                                <tr key={ind}>
                                    <td className="">{i.cantidad}</td>
                                    <td className="">{i.nombre_producto}</td>
                                    <td className="">{i.subtotal / i.cantidad}</td>
                                    <td className="">{i.subtotal}</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </Typography>
                <Typography variant="body2" color="text.secondary" className="d-flex justify-content-between my-2">
                    <Tooltip title="Estado">
                        <ShoppingCartCheckout/>
                    </Tooltip>
                    <b>{item.entregado ? 'Entregado' : 'Sin entregar'}</b>
                </Typography>
                <Typography variant="body2" color="text.secondary" className="d-flex justify-content-between my-2">
                    <Tooltip title="Cancelado">
                        <PriceCheck/>
                    </Tooltip>
                    <b>{item.cancelado ? 'Cancelado' : 'Falta cancelar'}</b>
                </Typography>
                <Typography variant="body2" color="text.secondary" className="d-flex justify-content-between my-2">
                    <Tooltip title="Total">
                        <Money/>
                    </Tooltip>
                    <b>{item.total || 'Total'} {' Bs'}</b>
                </Typography>
            </CardContent>
            <CardActions disableSpacing className="justify-content-end">
                <IconButton aria-label="Editar Venta" className="text-secondary" onClick={() => onEditVenta(item)}>
                    <Edit/>
                </IconButton>
                <IconButton aria-label="Imprimir Nota" className="text-primary" onClick={() => {props.printNota(item); navigate('/sales-print')}}>
                    <Article/>
                </IconButton>
            </CardActions>
        </Card>
    );
}
const mapStateToProps = (state) => {
    return {
        productosPrint: state.productos.productosPrint,
    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        printNota: (item) => dispatch(getVenta(item)),
        editProductos: (producto) => dispatch(addProductoSale(producto)),
        getCliente: (cliente) => dispatch(getCliente(cliente))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(VentaItem);
