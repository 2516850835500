import {
    Construction,
    SettingsAccessibility,
    ShoppingCartCheckout,
    ShoppingCartCheckoutSharp, ShoppingCartSharp,
    Storefront
} from "@mui/icons-material";

export const adminItems = () => {
    return [
        {
            name: 'Productos',
            routerLink: '/productos',
            icon: <Construction/>
        },
        {
            name: 'Lotes',
            routerLink: '/lotes',
            icon: <Storefront/>
        },        {
            name: 'Proveedores',
            routerLink: '/proveedores',
            icon: <SettingsAccessibility/>
        },
        {
            name: 'Vender',
            routerLink: '/sales',
            icon: <ShoppingCartCheckout/>
        },
        {
            name: 'Ventas',
            routerLink: '/ventas',
            icon: <ShoppingCartSharp/>
        },
    ]
}
