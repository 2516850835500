import {
    getProveedor,
    changeProveedorStatus,
    getProveedores,
    storeProveedor,
    updateProveedor,
    deleteProveedor
} from "./proveedores.actions";
import {
    deleteProveedorService,
    getProveedorService,
    getProveedoresService,
    storeProveedorService,
    updateProveedorService
} from "../../services/proveedor.service";

export const getProveedoresThunk = () => {
    return async (dispatch) => {
        dispatch(changeProveedorStatus('loading'));
        try {
            const data = await getProveedoresService();
            console.log("data proveedores", data);
            if (!data.error) {
                dispatch(getProveedores(data));
                dispatch(changeProveedorStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const getProveedorThunk = (id) => {
    return async (dispatch) => {
        dispatch(changeProveedorStatus('loading'));
        try {
            const data = await getProveedorService(id);
            console.log("data proveedor", data);
            if (!data.error) {
                dispatch(getProveedor(data));
                dispatch(changeProveedorStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const storeProveedorThunk = (obj) => {
    return async (dispatch) => {
        dispatch(changeProveedorStatus('loading'));
        try {
            const data = await storeProveedorService(obj);
            console.log("data", data);
            if (!data.error) {
                dispatch(storeProveedor(data));
                dispatch(changeProveedorStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const updateProveedorThunk = (obj, id) => {
    return async (dispatch) => {
        dispatch(changeProveedorStatus('loading'));
        try {
            const data = await updateProveedorService(obj, id);
            console.log("data edit", data);
            if (!data.error) {
                dispatch(updateProveedor(data));
                dispatch(changeProveedorStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const deleteProveedorThunk = (id) => {
    return async (dispatch) => {
        dispatch(changeProveedorStatus('loading'));
        try {
            const data = await deleteProveedorService(id);
            console.log("data edit", data);
            if (!data.error) {
                dispatch(deleteProveedor(data));
                dispatch(changeProveedorStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};
