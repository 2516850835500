import {FormControl, InputBase, Paper, Toolbar, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {FilterList, Search} from "@mui/icons-material";
import {useState} from "react";
import {Collapse} from "react-bootstrap";

const ProductoSearch = (props) => {
    const {setFilter} = props;
    const [showSearch, setShowSearch] = useState(true);

    const changeTitle = (e) => {
        setFilter(e.target.value);
    }

    return (
        <Toolbar sx={{pl: {sm: 2}, pr: {xs: 1, sm: 1}}} className="row my-2">

            <div className="col-md-7">
                {/*<Tooltip title="Filtros">
                    <IconButton onClick={() => setShowSearch(!showSearch)} aria-controls="search"
                                aria-expanded={showSearch}>
                        <FilterList/>
                    </IconButton>
                </Tooltip>*/}
            </div>
            <div className="col-md-12">
                <Collapse in={showSearch}>
                    <FormControl className="w-100">
                        {/*<InputLabel id="filter">Filters</InputLabel>
                    <Select labelId="filter"
                        value="all"
                        label="search">
                        <MenuItem value="all">All</MenuItem>
                    </Select>*/}
                        <Paper component="form"
                               sx={{p: '2px 4px', display: 'flex', alignItems: 'center', width: 'auto'}}>
                            <IconButton type="submit" sx={{p: '10px'}} aria-label="search">
                                <Search/>
                            </IconButton>
                            <InputBase sx={{ml: 1, flex: 1}}
                                       placeholder="Buscar..."
                                       onChange={changeTitle}
                                       inputProps={{'aria-label': 'Buscar productos'}}/>
                        </Paper>
                    </FormControl>
                </Collapse>
            </div>
        </Toolbar>
    );
}

export default ProductoSearch;
