const PrecioActionTypes = {
  CHANGE_STATUS_PRECIO: "CHANGE_STATUS_PRECIO",
  GET_PRECIOS: "GET_PRECIOS",
  GET_PRECIO: "GET_PRECIO",
  STORE_PRECIO: "STORE_PRECIO",
  UPDATE_PRECIO: "UPDATE_PRECIO",
  DELETE_PRECIO: "DELETE_PRECIO",
};

export default PrecioActionTypes;
