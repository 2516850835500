import MedidaActionTypes from "./medidas.types";

const INITIAL_STATE = {
    medidas: [],
    medida: null,
    status: 'initial',
};

const medidasReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MedidaActionTypes.CHANGE_STATUS_MEDIDA:
            return {...state, status: action.payload};
        case MedidaActionTypes.GET_MEDIDAS:
            return {...state, medidas: action.payload};
        case MedidaActionTypes.GET_MEDIDA:
            return {...state, medida: action.payload};
        case MedidaActionTypes.STORE_MEDIDA:
            return {...state, medida: action.payload};
        case MedidaActionTypes.UPDATE_MEDIDA:
            return {...state, medida: action.payload};
        case MedidaActionTypes.DELETE_MEDIDA:
            return {...state, medida: action.payload};
        default:
            return state;
    }
};


export default medidasReducer;
