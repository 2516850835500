import {connect} from "react-redux";
import './nota-venta.css';
import {useState, useEffect} from "react";
import moment from "moment";
import logo from '../../assets/img/logo.png';
import {getVenta} from "../../redux/ventas/ventas.actions";
import {useNavigate} from "react-router-dom";

const NotaVenta = (props) => {
    const [fecha, setFecha] = useState(moment(props.venta?.created_at || undefined).format('DD/MM/YYYY LT'));
    const navigate = useNavigate();

    useEffect(() => {
        console.log("HEREEEE")
        return () => {
            console.log("Nota venta desmontada")
            props.resetVenta();
        }
    }, [])

    const getTotal = () => {
        let total = 0;
        props.venta?.productos.map((item) => {
            // total += item.cantidad * item.precio;
            total += item.subtotal;
        })
        return total;
    }

    const getNro = () => {
        let strNro = props.venta?.id?.toString();
        while(strNro?.length < 10) {
            strNro = '0' + strNro;
        }
        return strNro;
    }

    return (
        <div>
            <div className="text-center">
                <button className="oculto-impresion btn btn-danger" onClick={() => window.print()}>Imprimir</button>
            </div>
            <div className="ticket">
                <div className="centrado">
                    <img
                        src={logo}
                        alt="Logotipo"/>
                </div>
                <p className="centrado title">
                    NOTA DE VENTA
                </p>
                <p className="centrado">
                    No. {getNro()}
                </p>
                <p className="centrado">
                    {fecha}
                </p>
                <br/>
                <p>
                    CLIENTE: {props.venta?.cliente?.nombre.toUpperCase() || 'NINGUNO'}
                </p>
                {/*<p>
                    NIT: {props.cliente?.nit || ''}
                </p>*/}
                <br/>
                <table>
                    <thead>
                    <tr>
                        <th className="cantidad">#</th>
                        <th className="producto">PRODUCTO</th>
                        <th className="precio">P.U.</th>
                        <th className="subtotal">SUB.</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.venta?.productos.map((item, index) =>
                        <tr key={index}>
                            <td className="cantidad">{item.cantidad}</td>
                            <td className="producto">{item.nombre_producto}</td>
                            <td className="precio">{item.subtotal / item.cantidad}</td>
                            <td className="subtotal">{item.subtotal}</td>
                        </tr>)}
                    <tr>
                        <td className="producto" colSpan="2">TOTAL</td>
                        <td className="precio" colSpan="2">{`${getTotal()} Bs.`}</td>
                    </tr>
                    </tbody>
                </table>
                <br/>
                <p className="centrado">
                    ¡GRACIAS POR SU COMPRA!
                </p>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        productosSale: state.productos.productosSale,
        cliente: state.clientes.cliente,
        venta: state.ventas.venta,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetVenta: () => dispatch(getVenta(null)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(NotaVenta);
