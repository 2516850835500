import {Card, CardContent, CardHeader, CircularProgress, IconButton} from "@mui/material";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import TextFieldWrapper from "../../layouts/forms/text-field-wrapper";
import ButtonWrapper from "../../layouts/forms/button-wrapper";
import {Component} from "react";
import {Add} from "@mui/icons-material";
import {getMedidasThunk} from "../../../redux/medidas/medidas.thunk";
import {connect} from "react-redux";
import {storeLoteThunk} from "../../../redux/lotes/lotes.thunk";
import {getProductosThunk} from "../../../redux/productos/productos.thunk";
import DateTimeFieldWrapper from "../../layouts/forms/date-time-field-wrapper";
import {getProveedoresThunk} from "../../../redux/proveedores/proveedores.thunk";
import ProveedorCreateDialog from "../../proveedor/proveedor-create/proveedor-create-dialog";
import MedidaCreateDialog from "../../medida/medida-create/medida-create-dialog";
import CheckFieldWrapper from "../../layouts/forms/check-field-wrapper";
import AutocompleteFieldWrapper from "../../layouts/forms/autocomplete-field-wrapper";
import moment from "moment";


class LoteCreate extends Component {

    state = {
        openProveedor: false,
        openMedida: false,
        hasFechaVencimiento: false,
        item: null,
        medidaAdquisicion: null
    }

    INITIAL_FROM_STATE = {
        fecha: moment().format('YYYY-MM-DD'),
        cantidad_adquisicion: '',
        cantidad_unidad: '',
        has_fecha_vencimiento: false,
        fecha_vencimiento: '',
        producto_id: '',
        producto: '',
        proveedor_id: '',
        proveedor: '',
        costo: '',
        cancelado: '',
        medida_adquisicion_id: '',
        medida_adquisicion: '',
    };

    FORM_VALIDATION = Yup.object().shape({
        fecha: Yup.string().required('La fecha de ingreso es requerida'),
        cantidad_adquisicion: Yup.string().required('La cantidad de adquisicion es requerida'),
        cantidad_unidad: Yup.string().required('La cantidada por unidad es requerida'),
        // has_fecha_vencimiento: '',
        // fecha_vencimiento: '',
        costo: Yup.string().required('El costo es requerido'),
        // cancelado: null,
        producto: Yup.mixed().required('El producto es requerido'),
        // proveedor: null,
        medida_adquisicion: Yup.mixed().required('La medida de adquisicion es requerida'),
    });

    async componentDidMount() {
        this.props.getProductos();
        this.props.getMedidas();
        this.props.getProveedores();
    }

    onStore = async (values) => {
        console.log(values);
        values.producto_id = values.producto.id;
        values.medida_adquisicion_id = values.medida_adquisicion.id;
        values.producto = '';
        values.medida_adquisicion = '';
        if (values.proveedor !== '') {
            values.proveedor_id = values.proveedor.id;
            values.proveedor = '';
        }
        await this.props.storeLote(values);
    }

    onAddProveedor = () => {
        this.setState({openProveedor: true});
    }

    onCloseProveedor = async () => {
        this.setState({openProveedor: false});
    }

    onAddMedida = () => {
        this.setState({openMedida: true});
    }

    onCloseMedida = async () => {
        this.setState({openMedida: false});
    }

    changeHasFechaVencimiento = (value) => {
        this.setState({hasFechaVencimiento: value})
    }

    setProducto = async (item) => {
        this.setState({item});
    }

    setMedida = async (item) => {
        this.setState({medidaAdquisicion: item});
    }

    render() {
        const {proveedores, medidas, productos, statusProveedor, statusMedida, statusProducto, statusLote} = this.props;
        const {hasFechaVencimiento} = this.state;
        return (
            <div>
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        {statusLote === 'loading' ?
                            <div className="text-center">
                                <CircularProgress/>
                            </div> :
                            <Card>
                                <CardHeader title="Registrar Lote"/>
                                <CardContent>
                                    <Formik initialValues={this.INITIAL_FROM_STATE}
                                            validationSchema={this.FORM_VALIDATION}
                                            onSubmit={values => this.onStore(values)}>
                                        <Form>
                                            <div className="my-3">
                                                <DateTimeFieldWrapper name="fecha" label="Fecha ingreso"/>
                                            </div>
                                            {statusProducto === 'loading' ?
                                                <div className="text-center">
                                                    <CircularProgress/>
                                                </div> :
                                                <div className="my-3 position-relative">
                                                    {/*<SelectFieldWrapper name="producto_id"
                                                                        label="Producto"
                                                                        labelValue="nombre"
                                                                        idValue="id"
                                                                        options={productos}/>*/}
                                                    <AutocompleteFieldWrapper name="producto"
                                                                              label="Producto"
                                                                              setItem={this.setProducto}
                                                                              labelValue="nombre"
                                                                              options={productos}/>

                                                </div>}
                                            {statusMedida === 'loading' ?
                                                <div className="text-center">
                                                    <CircularProgress/>
                                                </div> :
                                                <div className="my-3 position-relative">
                                                    <AutocompleteFieldWrapper name="medida_adquisicion"
                                                                              label="Medida Adquirida"
                                                                              labelValue="nombre"
                                                                              setItem={this.setMedida}
                                                                              options={medidas}/>
                                                    {!this.state.medidaAdquisicion ?
                                                        <div className="btn-top-right">
                                                            <IconButton className="bg-success text-white p-1"
                                                                        onClick={() => this.onAddMedida()}>
                                                                <Add fontSize="small"/>
                                                            </IconButton>
                                                        </div>: null}
                                                </div>}
                                            <div className="my-3">
                                                <TextFieldWrapper name="cantidad_adquisicion"
                                                                  label={this.state.medidaAdquisicion ? `¿Cuánts ${this.state.medidaAdquisicion.nombre}'s?` : '¿Cuantos?'}
                                                                  step="0.1"
                                                                  type="number"/>
                                            </div>
                                            <div className="my-3">
                                                <TextFieldWrapper name="cantidad_unidad"
                                                                  label={this.state.item && this.state.medidaAdquisicion ? `${this.state.item.medida}'s por ${this.state.medidaAdquisicion.nombre}` : 'Cantidad por unidad'}
                                                                  step="0.1"
                                                                  type="number"/>
                                            </div>
                                            <div className="my-3">
                                                <CheckFieldWrapper name="has_fecha_vencimiento"
                                                                   label="¿Tiene fecha de vencimiento?"
                                                                   changeValue={this.changeHasFechaVencimiento}/>
                                            </div>
                                            {hasFechaVencimiento ? <div className="my-3">
                                                <DateTimeFieldWrapper name="fecha_vencimiento"
                                                                      label="Fecha vencimiento"/>
                                            </div> : null}
                                            <div className="my-3">
                                                <TextFieldWrapper name="costo" label="Costo Total" type="number" step="0.1"/>
                                            </div>
                                            {statusProveedor === 'loading' ?
                                                <div className="text-center">
                                                    <CircularProgress/>
                                                </div> :
                                                <div className="my-3 position-relative">
                                                    <AutocompleteFieldWrapper name="proveedor_id"
                                                                              label="Proveedor"
                                                                              labelValue="nombre"
                                                                              options={proveedores}/>
                                                    <div className="btn-top-right">
                                                        <IconButton className="bg-success text-white p-1"
                                                                    onClick={() => this.onAddProveedor()}>
                                                            <Add fontSize="small"/>
                                                        </IconButton>
                                                    </div>
                                                </div>}
                                            <div className="my-3">
                                                <ButtonWrapper>REGISTRAR</ButtonWrapper>
                                            </div>
                                        </Form>
                                    </Formik>
                                </CardContent>
                            </Card>}
                    </div>
                </div>
                <ProveedorCreateDialog open={this.state.openProveedor} onClose={this.onCloseProveedor}/>
                <MedidaCreateDialog open={this.state.openMedida} onClose={this.onCloseMedida}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        productos: state.productos.productos,
        statusProducto: state.productos.status,

        medidas: state.medidas.medidas,
        statusMedida: state.medidas.status,

        proveedores: state.proveedores.proveedores,
        statusProveedor: state.proveedores.status,

        statusLote: state.lotes.status
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProductos: () => dispatch(getProductosThunk()),
        getProveedores: () => dispatch(getProveedoresThunk()),
        getMedidas: () => dispatch(getMedidasThunk()),
        storeLote: (obj) => dispatch(storeLoteThunk(obj)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoteCreate);
