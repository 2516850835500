import axios from "axios";

export const getProductosService = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/get-productos`);
    return data;
}

export const getProductosFilterService = async (filtro) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/get-productos-filter`, {filtro});
    return data;
}

export const getProductoService = async (id) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/get-producto/${id}`);
    return data;
}

export const storeProductoService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/store-producto`, obj);
    return data;
}

export const updateProductoService = async (obj, id) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/update-producto/${id}`, obj);
    return data;
}

export const updatePhotoProductoService = async (obj, id) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/update-photo-producto/${id}`, obj);
    return data;
}

export const deleteProductoService = async (id) => {
    const { data } = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/delete-producto/${id}`);
    return data;
}
