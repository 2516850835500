import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import {useState} from "react";
import {AddBusiness, AddCircle, ShoppingCartCheckout} from "@mui/icons-material";
import UserMobileMenu from "./user-mobile-menu";
import UserMenu from "./user-menu";
import SearchBar from "./search-bar";
import MenuOption from "./menu-option";
import {Tooltip} from "@mui/material";
import {useNavigate} from "react-router-dom";
import CartSale from "./cart-sale";

const Navbar = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const [mobileOpen, setMobileOpen] = useState(false);

    const navigate = useNavigate();

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const menuId = 'primary-search-account-menu';
    const mobileMenuId = 'primary-search-account-menu-mobile';

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton size="large"
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerToggle}
                                sx={{mr: 2}}>
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6"
                                noWrap
                                component="div"
                                sx={{display: {xs: 'none', sm: 'block'}}}>
                        Ferreteria
                    </Typography>
                    <SearchBar/>
                    <Box sx={{flexGrow: 1}}/>
                    <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                        <Tooltip title="Añadir Lote">
                            <IconButton size="large" color="inherit" onClick={() => navigate('/lote-create')}>
                                <AddBusiness/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Registrar Producto">
                            <IconButton size="large" color="inherit" onClick={() => navigate('/producto-create')}>
                                <AddCircle/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Vender">
                            <IconButton size="large" color="inherit" onClick={() => navigate('/sales')}>
                                <ShoppingCartCheckout/>
                            </IconButton>
                        </Tooltip>
                        <CartSale/>
                        <IconButton size="large"
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-controls={menuId}
                                    aria-haspopup="true"
                                    onClick={handleProfileMenuOpen}
                                    color="inherit">
                            <AccountCircle/>
                        </IconButton>
                    </Box>
                    <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                        <Tooltip title="Vender">
                            <IconButton size="large" color="inherit" onClick={() => navigate('/sales')}>
                                <ShoppingCartCheckout/>
                            </IconButton>
                        </Tooltip>
                        <CartSale/>
                        <IconButton size="large"
                                    aria-label="show more"
                                    aria-controls={mobileMenuId}
                                    aria-haspopup="true"
                                    onClick={handleMobileMenuOpen}
                                    color="inherit">
                            <AccountCircle/>
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <UserMobileMenu mobileMoreAnchorEl={mobileMoreAnchorEl}
                            mobileMenuId={mobileMenuId}
                            isMobileMenuOpen={isMobileMenuOpen}
                            handleMobileMenuClose={handleMobileMenuClose}
                            handleProfileMenuOpen={handleProfileMenuOpen}/>
            <UserMenu anchorEl={anchorEl}
                      menuId={menuId}
                      isMenuOpen={isMenuOpen}
                      handleMenuClose={handleMenuClose}/>
            <MenuOption mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle}/>

        </Box>
    );
}

export default Navbar;
