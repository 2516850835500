import {Card, CardContent, CardHeader, CircularProgress, Dialog, DialogContent, DialogTitle} from "@mui/material";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import TextFieldWrapper from "../../layouts/forms/text-field-wrapper";
import ButtonWrapper from "../../layouts/forms/button-wrapper";
import {Component} from "react";
import {getMedidasThunk, getMedidaThunk} from "../../../redux/medidas/medidas.thunk";
import {connect} from "react-redux";
import {getLotesThunk, updateLoteThunk} from "../../../redux/lotes/lotes.thunk";
import {getProductosThunk, getProductoThunk} from "../../../redux/productos/productos.thunk";
import DateTimeFieldWrapper from "../../layouts/forms/date-time-field-wrapper";
import {getProveedoresThunk, getProveedorThunk} from "../../../redux/proveedores/proveedores.thunk";
import CheckFieldWrapper from "../../layouts/forms/check-field-wrapper";
import AutocompleteFieldWrapper from "../../layouts/forms/autocomplete-field-wrapper";


class LoteEditDialog extends Component {

    state = {
        openProveedor: false,
        openMedida: false,
        hasFechaVencimiento: false,
        item: null,
        medidaAdquisicion: null
    }

    INITIAL_FROM_STATE = {
        fecha: this.props.lote.fecha,
        cantidad_adquisicion: this.props.lote.cantidad_adquisicion,
        cantidad_unidad: this.props.lote.cantidad_unidad,
        has_fecha_vencimiento: this.props.lote.has_fecha_vencimiento,
        fecha_vencimiento: this.props.lote.fecha_vencimiento,
        producto_id: '',
        producto: '',
        proveedor_id: '',
        proveedor: '',
        costo: this.props.lote.costo,
        // cancelado: this.props.lote.cancelado,
        medida_adquisicion_id: '',
        medida_adquisicion: '',
    };

    FORM_VALIDATION = Yup.object().shape({
        fecha: Yup.string().required('La fecha de ingreso es requerida'),
        cantidad_adquisicion: Yup.string().required('La cantidad de adquisicion es requerida'),
        cantidad_unidad: Yup.string().required('La cantidada por unidad es requerida'),
        // has_fecha_vencimiento: '',
        // fecha_vencimiento: '',
        costo: Yup.string().required('El costo es requerido'),
        // cancelado: null,
        producto: Yup.mixed().required('El producto es requerido'),
        // proveedor: null,
        medida_adquisicion: Yup.mixed().required('La medida de adquisicion es requerida'),
    });

    async componentDidMount() {
        const {lote} = this.props;
        await this.props.getProductos();
        await this.props.getMedidas();
        await this.props.getProveedores();

        await this.props.getProducto(lote.producto_id);
        await this.props.getMedida(lote.medida_adquisicion_id);

        if (this.props.proveedores) {
            await this.props.getProveedor(lote.proveedor_id);
        }

        this.INITIAL_FROM_STATE.producto = this.props.producto;
        this.INITIAL_FROM_STATE.medida_adquisicion = this.props.medida;
        this.INITIAL_FROM_STATE.proveedor = this.props.proveedor ? this.props.proveedor : null;

        this.setProducto(this.props.producto);
        this.setMedida(this.props.medida);
        this.changeHasFechaVencimiento(this.props.lote.has_fecha_vencimiento);
    }

    onUpdate = async (values) => {
        console.log(values);
        values.producto_id = values.producto.id;
        values.medida_adquisicion_id = values.medida_adquisicion.id;
        values.producto = '';
        values.medida_adquisicion = '';
        if (values.proveedor !== '') {
            values.proveedor_id = values.proveedor.id;
            values.proveedor = '';
        }
        await this.props.updateLote(values, this.props.lote.id);
        await this.props.getLotes();
    }


    changeHasFechaVencimiento = (value) => {
        this.setState({hasFechaVencimiento: value})
    }

    setProducto = async (item) => {
        this.setState({item});
    }

    setMedida = async (item) => {
        this.setState({medidaAdquisicion: item});
    }

    render() {
        const {proveedores, medidas, productos, statusProveedor, statusMedida, statusProducto, statusLote, open, onClose} = this.props;
        const {hasFechaVencimiento} = this.state;
        return (
            <Dialog
                fullWidth={false}
                open={open}
                onClose={() => onClose()}>
                <DialogTitle>Actualizar Lote</DialogTitle>
                <DialogContent>
                        {statusLote === 'loading' ?
                            <div className="text-center">
                                <CircularProgress/>
                            </div> :
                            <Card>
                                <CardContent>
                                    <Formik initialValues={this.INITIAL_FROM_STATE}
                                            validationSchema={this.FORM_VALIDATION}
                                            onSubmit={values => this.onUpdate(values)}>
                                        <Form>
                                            <div className="my-3">
                                                <DateTimeFieldWrapper name="fecha" label="Fecha ingreso"/>
                                            </div>
                                            {statusProducto === 'loading' ?
                                                <div className="text-center">
                                                    <CircularProgress/>
                                                </div> :
                                                <div className="my-3 position-relative">
                                                    {/*<SelectFieldWrapper name="producto_id"
                                                                        label="Producto"
                                                                        labelValue="nombre"
                                                                        idValue="id"
                                                                        options={productos}/>*/}
                                                    <AutocompleteFieldWrapper name="producto"
                                                                              label="Producto"
                                                                              setItem={this.setProducto}
                                                                              labelValue="nombre"
                                                                              options={productos}/>

                                                </div>}
                                            {statusMedida === 'loading' ?
                                                <div className="text-center">
                                                    <CircularProgress/>
                                                </div> :
                                                <div className="my-3 position-relative">
                                                    <AutocompleteFieldWrapper name="medida_adquisicion"
                                                                              label="Medida Adquirida"
                                                                              labelValue="nombre"
                                                                              setItem={this.setMedida}
                                                                              options={medidas}/>
                                                    {/*
                                                        <div className="btn-top-right">
                                                            <IconButton className="bg-success text-white p-1"
                                                                        onClick={() => this.onAddMedida()}>
                                                                <Add fontSize="small"/>
                                                            </IconButton>
                                                        </div>*/}
                                                </div>}
                                            <div className="my-3">
                                                <TextFieldWrapper name="cantidad_adquisicion"
                                                                  label={this.state.medidaAdquisicion ? `¿Cuánts ${this.state.medidaAdquisicion.nombre}'s?` : '¿Cuantos?'}
                                                                  type="number"/>
                                            </div>
                                            <div className="my-3">
                                                <TextFieldWrapper name="cantidad_unidad"
                                                                  label={this.state.item && this.state.medidaAdquisicion ? `${this.state.item.medida}'s por ${this.state.medidaAdquisicion.nombre}` : 'Cantidad por unidad'}
                                                                  type="number"/>
                                            </div>
                                            <div className="my-3">
                                                <CheckFieldWrapper name="has_fecha_vencimiento"
                                                                   label="¿Tiene fecha de vencimiento?"
                                                                   changeValue={this.changeHasFechaVencimiento}/>
                                            </div>
                                            {hasFechaVencimiento ? <div className="my-3">
                                                <DateTimeFieldWrapper name="fecha_vencimiento"
                                                                      label="Fecha vencimiento"/>
                                            </div> : null}
                                            <div className="my-3">
                                                <TextFieldWrapper name="costo" label="Costo Total" type="number"/>
                                            </div>
                                            {statusProveedor === 'loading' ?
                                                <div className="text-center">
                                                    <CircularProgress/>
                                                </div> :
                                                <div className="my-3 position-relative">
                                                    <AutocompleteFieldWrapper name="proveedor_id"
                                                                              label="Proveedor"
                                                                              labelValue="nombre"
                                                                              options={proveedores}/>
                                                    {/*<div className="btn-top-right">
                                                        <IconButton className="bg-success text-white p-1"
                                                                    onClick={() => this.onAddProveedor()}>
                                                            <Add fontSize="small"/>
                                                        </IconButton>
                                                    </div>*/}
                                                </div>}
                                            <div className="my-3">
                                                <ButtonWrapper>ACTUALIZAR</ButtonWrapper>
                                            </div>
                                        </Form>
                                    </Formik>
                                </CardContent>
                            </Card>}
                </DialogContent>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        productos: state.productos.productos,
        producto: state.productos.producto,
        statusProducto: state.productos.status,

        medidas: state.medidas.medidas,
        medida: state.medidas.medida,
        statusMedida: state.medidas.status,

        proveedores: state.proveedores.proveedores,
        proveedor: state.proveedores.proveedor,
        statusProveedor: state.proveedores.status,

        statusLote: state.lotes.status
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProductos: () => dispatch(getProductosThunk()),
        getProveedores: () => dispatch(getProveedoresThunk()),
        getMedidas: () => dispatch(getMedidasThunk()),
        getLotes: () => dispatch(getLotesThunk()),

        getProducto: (id) => dispatch(getProductoThunk(id)),
        getProveedor: (id) => dispatch(getProveedorThunk(id)),
        getMedida: (id) => dispatch(getMedidaThunk(id)),

        updateLote: (obj, id) => dispatch(updateLoteThunk(obj, id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoteEditDialog);
