import {Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar} from "@mui/material";
import {adminItems} from "../../../admin-items";
import {useNavigate} from "react-router-dom";

const drawerWidth = 240;

const MenuOption = (props) => {
    const {window, mobileOpen, handleDrawerToggle} = props;
    const container = window !== undefined ? () => window().document.body : undefined;
    const items = adminItems();
    const navigate = useNavigate();

    const onRoute = (url) => {
        navigate(url);
        handleDrawerToggle();
    }

    return (
        <Box component="nav" sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
             aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}>
                <div>
                    <Toolbar className="justify-content-center">
                        <h4>FERRETERIA</h4>
                    </Toolbar>
                    <Divider/>
                    <List>
                        {items.map((item, index) =>
                            <ListItem key={index} disablePadding>
                                <ListItemButton onClick={() => onRoute(item.routerLink)}>
                                    <ListItemIcon>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={item.name}/>
                                </ListItemButton>
                            </ListItem>
                        )}
                    </List>
                </div>
            </Drawer>
        </Box>
    )
}

export default MenuOption;
