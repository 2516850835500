import {Card, CardContent, CardHeader, CardMedia, CircularProgress, IconButton} from "@mui/material";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import TextFieldWrapper from "../../layouts/forms/text-field-wrapper";
import ButtonWrapper from "../../layouts/forms/button-wrapper";
import SelectFieldWrapper from "../../layouts/forms/select-field-wrapper";
import {Component} from "react";
import FileFieldWrapper from "../../layouts/forms/file-field-wrapper";
import {Add} from "@mui/icons-material";
import {getMarcasThunk} from "../../../redux/marcas/marcas.thunk";
import {getMedidasThunk} from "../../../redux/medidas/medidas.thunk";
import {connect} from "react-redux";
import MarcaCreateDialog from "../../marca/marca-create/marca-create-dialog";
import {storeProductoThunk} from "../../../redux/productos/productos.thunk";
import {toFormData} from "../../../helpers/toFormData";
import MedidaCreateDialog from "../../medida/medida-create/medida-create-dialog";
import AutocompleteFieldWrapper from "../../layouts/forms/autocomplete-field-wrapper";


class ProductoCreate extends Component {

    state = {
        // tiposProducto: [],
        img: null,
        file: null,
        openMarca: false,
        openMedida: false,
        marca: null,
        medida: null
    }

    INITIAL_FROM_STATE = {
        nombre: '',
        marca_id: '',
        marca: '',
        descripcion: '',
        foto: null,
        medida_id: '',
        medida: '',
        // tipo_producto_id: ''
    };

    FORM_VALIDATION = Yup.object().shape({
        nombre: Yup.string().required('El nombre es requerido'),
        marca: Yup.mixed().required('La marca es requerida'),
        // marca_id: '',
        // descripcion: Yup.string().required('La descripcion es requerida'),
        // foto: '',
        medida: Yup.mixed().required('La medida es requerida'),
        // tipo_producto_id: ''
    });

    async componentDidMount() {
        this.props.getMarcas();
        this.props.getMedidas();
    }

    onChangeImg = (e) => {
        if (e.target.files.length > 0) {
            const currentFile = e.target.files[0];
            this.setState({img: URL.createObjectURL(currentFile), file: currentFile});
        }
    }

    onDeleteImage = () => {
        this.setState({img: null, file: null});
    }

    onStore = async (values) => {
        console.log(values);

        values.medida_id = values.medida.id;
        values.medida = '';

        if (values.marca !== '') {
            values.marca_id = values.marca.id;
            values.marca = '';
        }

        const form = toFormData(values);
        form.append('foto', this.state.file);
        console.log(form);

        await this.props.storeProducto(form);
        this.onDeleteImage();
    }

    onAddMarca = () => {
        this.setState({openMarca: true});
    }

    onCloseMarca = async () => {
        this.setState({openMarca: false});
    }

    onAddMedida = () => {
        this.setState({openMedida: true});
    }

    onCloseMedida = async () => {
        this.setState({openMedida: false});
    }

    setMarca = async (item) => {
        this.setState({marca: item});
    }

    setMedida = async (item) => {
        this.setState({medida: item});
    }

    render() {
        const {img, file} = this.state;
        const {marcas, medidas, statusMarca, statusMedida, statusProducto} = this.props;
        return (
            <div>
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        { statusProducto === 'loading'?
                        <div className="text-center">
                            <CircularProgress/>
                        </div> :
                        <Card>
                            <CardHeader title="Registrar Producto"/>
                            {/*<CardMedia component="img"
                                       className="w-50 mx-auto"
                                       image={userImg}
                                       alt={this.props.user.firstName}/>*/}
                            {img ?
                                <label htmlFor="contained-button-file"><CardMedia component="img" height={150}
                                                                                  image={img}/></label> : null}
                            <CardContent>
                                <Formik initialValues={this.INITIAL_FROM_STATE}
                                        validationSchema={this.FORM_VALIDATION}
                                        onSubmit={values => this.onStore(values)}>
                                    <Form>
                                        <div className="my-3">
                                            <TextFieldWrapper name="nombre" label="Nombre"/>
                                        </div>
                                        {statusMarca === 'loading' ?
                                            <div className="text-center">
                                                <CircularProgress/>
                                            </div> :
                                            <div className="my-3 position-relative">
                                                <AutocompleteFieldWrapper name="marca"
                                                                          label="Marca"
                                                                          setItem={this.setMarca}
                                                                          labelValue="nombre"
                                                                          options={marcas}/>
                                                {/*<Select name="marca_id" options={marcas} valueKey="id" labelKey="nombre"
                                                onChange={() => console.log('change')}
                                                onMenuOpen={() => console.log('open')}
                                                onMenuClose={() => console.log('close')}/>*/}
                                                <div className="btn-top-right">
                                                    <IconButton className="bg-success text-white p-1"
                                                                onClick={() => this.onAddMarca()}>
                                                        <Add fontSize="small"/>
                                                    </IconButton>
                                                </div>
                                            </div>}
                                        {statusMedida === 'loading' ?
                                            <div className="text-center">
                                                <CircularProgress/>
                                            </div> :
                                            <div className="my-3 position-relative">
                                                <AutocompleteFieldWrapper name="medida"
                                                                          label="Medida de venta"
                                                                          setItem={this.setMedida}
                                                                          labelValue="nombre"
                                                                          options={medidas}/>
                                                {/*<Select name="medida_id" options={medidas} valueKey="id" labelKey="nombre"
                                                onMenuOpen={() => console.log('open')}
                                                onMenuClose={() => console.log('close')}/>*/}
                                                <div className="btn-top-right">
                                                    <IconButton className="bg-success text-white p-1"
                                                                onClick={() => this.onAddMedida()}>
                                                        <Add fontSize="small"/>
                                                    </IconButton>
                                                </div>
                                            </div>}
                                        <div className="my-3">
                                            <FileFieldWrapper name="foto" accept="image/*"
                                                              onChangeImg={this.onChangeImg}
                                                              file={file} onDelete={this.onDeleteImage}/>
                                        </div>
                                        <div className="my-3">
                                            <TextFieldWrapper name="descripcion" label="Descripcion" multiline={true}
                                                              rows={3}/>
                                        </div>
                                        <div className="my-3">
                                            <ButtonWrapper>INGRESAR</ButtonWrapper>
                                        </div>
                                    </Form>
                                </Formik>
                            </CardContent>
                        </Card>}
                    </div>
                </div>
                <MarcaCreateDialog open={this.state.openMarca} onClose={this.onCloseMarca}/>
                <MedidaCreateDialog open={this.state.openMedida} onClose={this.onCloseMedida}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        marcas: state.marcas.marcas,
        statusMarca: state.marcas.status,
        medidas: state.medidas.medidas,
        statusMedida: state.medidas.status,
        statusProducto: state.productos.status
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMarcas: () => dispatch(getMarcasThunk()),
        storeProducto: (obj) => dispatch(storeProductoThunk(obj)),
        getMedidas: () => dispatch(getMedidasThunk()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductoCreate);
