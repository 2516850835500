import {
    getProducto,
    changeProductoStatus,
    getProductos,
    storeProducto,
    updateProducto,
    deleteProducto, updatePhotoProducto, getProductosFilter
} from "./productos.actions";
import {
    deleteProductoService,
    getProductoService,
    getProductosService,
    storeProductoService, updatePhotoProductoService,
    updateProductoService
} from "../../services/producto.service";

export const getProductosThunk = () => {
    return async (dispatch) => {
        dispatch(changeProductoStatus('loading'));
        try {
            const data = await getProductosService();
            console.log("data productos", data);
            if (!data.error) {
                dispatch(getProductos(data));
                dispatch(changeProductoStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const getProductosFilterThunk = () => {
    return async (dispatch) => {
        // dispatch(changeProductoStatus('loading'));
        try {
            const data = await getProductosService();
            if (!data.error) {
                dispatch(getProductosFilter(data));
                // dispatch(changeProductoStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const getProductoThunk = (id) => {
    return async (dispatch) => {
        dispatch(changeProductoStatus('loading'));
        try {
            const data = await getProductoService(id);
            console.log("data producto", data);
            if (!data.error) {
                dispatch(getProducto(data));
                dispatch(changeProductoStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const storeProductoThunk = (obj) => {
    return async (dispatch) => {
        dispatch(changeProductoStatus('loading'));
        try {
            const data = await storeProductoService(obj);
            console.log("data", data);
            if (!data.error) {
                dispatch(storeProducto(data));
                dispatch(changeProductoStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const updateProductoThunk = (obj, id) => {
    return async (dispatch) => {
        dispatch(changeProductoStatus('loading'));
        try {
            const data = await updateProductoService(obj, id);
            console.log("data edit", data);
            if (!data.error) {
                dispatch(updateProducto(data));
                dispatch(changeProductoStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const updatePhotoProductoThunk = (obj, id) => {
    return async (dispatch) => {
        dispatch(changeProductoStatus('loading'));
        try {
            const data = await updatePhotoProductoService(obj, id);
            console.log("data edit photo", data);
            if (!data.error) {
                dispatch(updatePhotoProducto(data));
                dispatch(changeProductoStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const deleteProductoThunk = (id) => {
    return async (dispatch) => {
        dispatch(changeProductoStatus('loading'));
        try {
            const data = await deleteProductoService(id);
            console.log("data edit", data);
            if (!data.error) {
                dispatch(deleteProducto(data));
                dispatch(changeProductoStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};
