import {combineReducers} from "redux";
import usersReducer from "./users/users.reducer";
import productosReducer from "./productos/productos.reducer";
import marcasReducer from "./marcas/marcas.reducer";
import medidasReducer from "./medidas/medidas.reducer";
import lotesReducer from "./lotes/lotes.reducer";
import proveedoresReducer from "./proveedores/proveedores.reducer";
import preciosReducer from "./precios/precios.reducer";
import pagosReducer from "./pagos/pagos.reducer";
import clientesReducer from "./clientes/clientes.reducer";
import ventasReducer from "./ventas/ventas.reducer";

import {reducer as toastrReducer} from 'react-redux-toastr'


const rootReducer = combineReducers({
    users: usersReducer,
    productos: productosReducer,
    marcas: marcasReducer,
    medidas: medidasReducer,
    lotes: lotesReducer,
    proveedores: proveedoresReducer,
    precios: preciosReducer,
    pagos: pagosReducer,
    clientes: clientesReducer,
    ventas: ventasReducer,
    toastr: toastrReducer
});

export default rootReducer;
