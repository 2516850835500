import {
    getVenta,
    changeVentaStatus,
    getVentas,
    storeVenta,
    updateVenta,
    deleteVenta
} from "./ventas.actions";
import {
    deleteVentaService,
    getVentaService,
    getVentasService,
    storeVentaService,
    updateVentaService
} from "../../services/venta.service";

export const getVentasThunk = () => {
    return async (dispatch) => {
        dispatch(changeVentaStatus('loading'));
        try {
            const data = await getVentasService();
            console.log("data ventas", data);
            if (!data.error) {
                dispatch(getVentas(data));
                dispatch(changeVentaStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const getVentaThunk = (id) => {
    return async (dispatch) => {
        dispatch(changeVentaStatus('loading'));
        try {
            const data = await getVentaService(id);
            console.log("data venta", data);
            if (!data.error) {
                dispatch(getVenta(data));
                dispatch(changeVentaStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const storeVentaThunk = (obj) => {
    return async (dispatch) => {
        dispatch(changeVentaStatus('loading'));
        try {
            const data = await storeVentaService(obj);
            console.log("data", data);
            if (!data.error) {
                dispatch(storeVenta(data));
                dispatch(changeVentaStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const updateVentaThunk = (obj, id) => {
    return async (dispatch) => {
        dispatch(changeVentaStatus('loading'));
        try {
            const data = await updateVentaService(obj, id);
            console.log("data edit", data);
            if (!data.error) {
                dispatch(updateVenta(data));
                dispatch(changeVentaStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const deleteVentaThunk = (id) => {
    return async (dispatch) => {
        dispatch(changeVentaStatus('loading'));
        try {
            const data = await deleteVentaService(id);
            console.log("data edit", data);
            if (!data.error) {
                dispatch(deleteVenta(data));
                dispatch(changeVentaStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};
