import {Avatar, Badge, IconButton, ListItemAvatar, ListItemText, Menu, Typography} from "@mui/material";
import {Delete, Print, ShoppingCart, ShoppingCartCheckout} from "@mui/icons-material";
import {connect} from "react-redux";
import {useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import {loadFile} from "../../../helpers/files";
import {addProductoPrint} from "../../../redux/productos/productos.actions";
import './cart-sale.css';
import {useNavigate} from "react-router-dom";

const CartPrint = (props) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const deleteProducto = (item) => {
        const newProductos = props.productosPrint.filter((prod) => {
            return item.producto.id !== prod.producto.id;
        })
        props.updateProductoPrint(newProductos);
    }

    return (
        <div>
            <IconButton size="large" aria-label="show 4 new mails"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        color="inherit">
                <Badge badgeContent={props.productosPrint.length > 0 ? props.productosPrint.length : null } color="error">
                    <Print/>
                </Badge>
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open && props.productosPrint.length > 0}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                {props.productosPrint.map((item, index) =>
                    <MenuItem key={index}>
                        <ListItemAvatar>
                            <Avatar
                                alt={item.producto.nombre}
                                src={loadFile(item.producto.foto)}/>
                        </ListItemAvatar>
                        <ListItemText primary={<span>{item.cantidad}<small className="ms-1 sm-text">{item.producto.medida}</small></span>} className="me-2"/>
                        <ListItemText secondary={<small>{item.producto.nombre}</small>}/>
                        <Typography variant="body2" color="text.primary" className="ms-3">
                            <IconButton className="ms-2" onClick={() => deleteProducto(item)}>
                                <Delete color="error"/>
                            </IconButton>
                        </Typography>

                    </MenuItem>)}
                <MenuItem>
                    <IconButton className="me-2" onClick={() => navigate('/ticket-print')}>
                        <Print color="success"/>
                    </IconButton>
                </MenuItem>
            </Menu>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        productosPrint: state.productos.productosPrint,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateProductoPrint: (producto) => dispatch(addProductoPrint(producto)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(CartPrint);
