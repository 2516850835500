import {connect} from "react-redux";
import './nota-venta.css';
import {useState} from "react";
import moment from "moment";
import logo from '../../assets/img/logo.png';

const Ticket = (props) => {
    const [fecha, setFecha] = useState(moment().format('DD/MM/YYYY LT'));

    const getTotal = () => {
        let total = 0;
        props.productosPrint.map((item) => {
            total += item.cantidad * item.producto.precio;
        })
        return total;
    }

    return (
        <div>
            <div className="text-center">
                <button className="oculto-impresion btn btn-danger" onClick={() => window.print()}>Imprimir</button>
            </div>
            {props.productosPrint.map((prod) =>
                <div className="ticket">
                    {/*<div className="centrado">
                    <img
                        src={logo}
                        alt="Logotipo"/>
                </div>*/}
                    <div className=" d-flex justify-content-between border-dark border-3 border-bottom py-3">
                        <p className="text-start fs-7">
                            <small>{prod.producto.nombre.toUpperCase() || ''}</small>
                        </p>
                        <p className="title-ticket">
                            {prod.producto.precio + ""}
                            <span className="fs-6">Bs</span>
                        </p>
                    </div>
                    {/*<hr size="4" className="text-black opacity-100"/>*/}
                </div>)}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        productosPrint: state.productos.productosPrint,
        cliente: state.clientes.cliente,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};


export default connect(mapStateToProps, mapDispatchToProps)(Ticket);
