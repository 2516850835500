import ProductoActionTypes from "./productos.types";

//ACTION CREATORS

export const changeProductoStatus = (payload) => ({
    type: ProductoActionTypes.CHANGE_STATUS_PRODUCTO,
    payload,
});

export const getProductos = (payload) => ({
    type: ProductoActionTypes.GET_PRODUCTOS,
    payload,
});

export const getProductosFilter = (payload) => ({
    type: ProductoActionTypes.GET_PRODUCTOS_FILTER,
    payload,
});

export const getProducto = (payload) => ({
    type: ProductoActionTypes.GET_PRODUCTO,
    payload,
});

export const storeProducto = (payload) => ({
    type: ProductoActionTypes.STORE_PRODUCTO,
    payload,
});

export const updateProducto = (payload) => ({
    type: ProductoActionTypes.UPDATE_PRODUCTO,
    payload,
});

export const updatePhotoProducto = (payload) => ({
    type: ProductoActionTypes.UPDATE_PHOTO_PRODUCTO,
    payload,
});

export const deleteProducto = (payload) => ({
    type: ProductoActionTypes.DELETE_PRODUCTO,
    payload,
});

export const addProductoSale = (payload) => ({
    type: ProductoActionTypes.ADD_PRODUCTO_SALE,
    payload,
});

export const resetProductoSale = () => ({
    type: ProductoActionTypes.RESET_PRODUCTO_SALE,
});

export const addProductoPrint = (payload) => ({
    type: ProductoActionTypes.ADD_PRODUCTO_PRINT,
    payload,
});

export const resetProductoPrint = () => ({
    type: ProductoActionTypes.RESET_PRODUCTO_PRINT,
});
