import {
    getMedida,
    changeMedidaStatus,
    getMedidas,
    storeMedida,
    updateMedida,
    deleteMedida
} from "./medidas.actions";
import {
    deleteMedidaService,
    getMedidaService,
    getMedidasService,
    storeMedidaService,
    updateMedidaService
} from "../../services/medida.service";

export const getMedidasThunk = () => {
    return async (dispatch) => {
        dispatch(changeMedidaStatus('loading'));
        try {
            const data = await getMedidasService();
            console.log("data medidas", data);
            if (!data.error) {
                dispatch(getMedidas(data));
                dispatch(changeMedidaStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const getMedidaThunk = (id) => {
    return async (dispatch) => {
        dispatch(changeMedidaStatus('loading'));
        try {
            const data = await getMedidaService(id);
            console.log("data medida", data);
            if (!data.error) {
                dispatch(getMedida(data));
                dispatch(changeMedidaStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const storeMedidaThunk = (obj) => {
    return async (dispatch) => {
        dispatch(changeMedidaStatus('loading'));
        try {
            const data = await storeMedidaService(obj);
            console.log("data store medida", data);
            if (!data.error) {
                dispatch(storeMedida(data));
                dispatch(changeMedidaStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const updateMedidaThunk = (obj, id) => {
    return async (dispatch) => {
        dispatch(changeMedidaStatus('loading'));
        try {
            const data = await updateMedidaService(obj, id);
            console.log("data edit medida", data);
            if (!data.error) {
                dispatch(updateMedida(data));
                dispatch(changeMedidaStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const deleteMedidaThunk = (id) => {
    return async (dispatch) => {
        dispatch(changeMedidaStatus('loading'));
        try {
            const data = await deleteMedidaService(id);
            console.log("data delete medida", data);
            if (!data.error) {
                dispatch(deleteMedida(data));
                dispatch(changeMedidaStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};
