import {CircularProgress} from "@mui/material";

const LoadingMsg = (props) => {
  return (
      <div className="text-center">
          <CircularProgress/>
          <p>{props.msg}</p>
      </div>
  )
}

export default LoadingMsg;
