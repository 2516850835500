import {
    getMarca,
    changeMarcaStatus,
    getMarcas,
    storeMarca,
    updateMarca,
    deleteMarca
} from "./marcas.actions";
import {
    deleteMarcaService,
    getMarcaService,
    getMarcasService,
    storeMarcaService,
    updateMarcaService
} from "../../services/marca.service";

export const getMarcasThunk = () => {
    return async (dispatch) => {
        dispatch(changeMarcaStatus('loading'));
        try {
            const data = await getMarcasService();
            console.log("data marcas", data);
            if (!data.error) {
                dispatch(getMarcas(data));
                dispatch(changeMarcaStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const getMarcaThunk = (id) => {
    return async (dispatch) => {
        dispatch(changeMarcaStatus('loading'));
        try {
            const data = await getMarcaService(id);
            console.log("data marca", data);
            if (!data.error) {
                dispatch(getMarca(data));
                dispatch(changeMarcaStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const storeMarcaThunk = (obj) => {
    return async (dispatch) => {
        dispatch(changeMarcaStatus('loading'));
        try {
            const data = await storeMarcaService(obj);
            console.log("data marcas", data);
            if (!data.error) {
                dispatch(storeMarca(data));
                dispatch(changeMarcaStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const updateMarcaThunk = (obj, id) => {
    return async (dispatch) => {
        dispatch(changeMarcaStatus('loading'));
        try {
            const data = await updateMarcaService(obj, id);
            console.log("data edit", data);
            if (!data.error) {
                dispatch(updateMarca(data));
                dispatch(changeMarcaStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const deleteMarcaThunk = (id) => {
    return async (dispatch) => {
        dispatch(changeMarcaStatus('loading'));
        try {
            const data = await deleteMarcaService(id);
            console.log("data delete marca", data);
            if (!data.error) {
                dispatch(deleteMarca(data));
                dispatch(changeMarcaStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};
