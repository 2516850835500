const ProductoActionTypes = {
  CHANGE_STATUS_PRODUCTO: "CHANGE_STATUS_PRODUCTO",
  GET_PRODUCTOS: "GET_PRODUCTOS",
  GET_PRODUCTOS_FILTER: "GET_PRODUCTOS_FILTER",
  GET_PRODUCTO: "GET_PRODUCTO",
  STORE_PRODUCTO: "STORE_PRODUCTO",
  UPDATE_PRODUCTO: "UPDATE_PRODUCTO",
  UPDATE_PHOTO_PRODUCTO: "UPDATE_PHOTO_PRODUCTO",
  DELETE_PRODUCTO: "DELETE_PRODUCTO",
  ADD_PRODUCTO_SALE: "ADD_PRODUCTO_SALE",
  RESET_PRODUCTO_SALE: "RESET_PRODUCTO_SALE",
  ADD_PRODUCTO_PRINT: "ADD_PRODUCTO_PRINT",
  RESET_PRODUCTO_PRINT: "RESET_PRODUCTO_PRINT",
};

export default ProductoActionTypes;
