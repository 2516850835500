import PrecioActionTypes from "./precios.types";

//ACTION CREATORS

export const changePrecioStatus = (payload) => ({
    type: PrecioActionTypes.CHANGE_STATUS_PRECIO,
    payload,
});

export const getPrecios = (payload) => ({
    type: PrecioActionTypes.GET_PRECIOS,
    payload,
});

export const getPrecio = (payload) => ({
    type: PrecioActionTypes.GET_PRECIO,
    payload,
});

export const storePrecio = (payload) => ({
    type: PrecioActionTypes.STORE_PRECIO,
    payload,
});

export const updatePrecio = (payload) => ({
    type: PrecioActionTypes.UPDATE_PRECIO,
    payload,
});

export const deletePrecio = (payload) => ({
    type: PrecioActionTypes.DELETE_PRECIO,
    payload,
});
