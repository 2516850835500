import axios from "axios";

export const getLotesService = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/get-lotes`);
    return data;
}

export const getLoteService = async (id) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/get-lote/${id}`);
    return data;
}

export const storeLoteService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/store-lote`, obj);
    return data;
}

export const updateLoteService = async (obj, id) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/update-lote/${id}`, obj);
    return data;
}

export const deleteLoteService = async (id) => {
    const { data } = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/delete-lote/${id}`);
    return data;
}
