import {Card, CardContent, CardHeader, Typography} from "@mui/material";
import {format} from "date-fns";
import {Call} from "@mui/icons-material";

const ProveedorItem = (props) => {
    const {item} = props;
    return (
        <Card className="h-100 position-relative">
            <CardHeader
                title={item.nombre}
                subheader={<span><Call/>{item.celular}</span>}/>
            <CardContent>
                <Typography variant="body2" color="text.info">
                    <h6 className="m-0 text-primary">Lista productos</h6>
                    <b className="text-black-50 text-description"><em>{item.lista_productos || 'None'}</em></b>
                </Typography>
            </CardContent>

        </Card>
    );
}
export default ProveedorItem;
