import {useRoutes} from "react-router-dom";
import Home from "./home/home";
import Login from "./login/login";
import ProductoIndex from "./components/producto/producto-index/producto-index";
import ProductoSaleIndex from "./components/producto/producto-sale/producto-sale-index";
import ProductoCreate from "./components/producto/producto-create/producto-create";
import LoteCreate from "./components/lote/lote-create/lote-create";
import LoteIndex from "./components/lote/lote-index/lote-index";
import ProveedorIndex from "./components/proveedor/proveedor-index/proveedor-index";
import ProductoSaleCreate from "./components/producto/producto-sale/producto-sale-create";
import NotaVenta from "./components/files/nota-venta";
import Ticket from "./components/files/ticket";
import VentaIndex from "./components/venta/venta-index/venta-index";

const AppRouting = () => {
    return useRoutes([
        {
            path: '/',
            // element: <Home/>}
            element: <ProductoSaleIndex/>
        },
        {
            path: '/login',
            element: <Login/>
        },
        {
            path: '/productos',
            element: <ProductoIndex/>
        },
        {
            path: '/producto-create',
            element: <ProductoCreate/>
        },
        {
            path: '/lote-create',
            element: <LoteCreate/>
        },
        {
            path: '/lotes',
            element: <LoteIndex/>
        },
        {
            path: '/proveedores',
            element: <ProveedorIndex/>
        },
        {
            path: '/sales',
            element: <ProductoSaleIndex/>
        },
        {
            path: '/sales-create',
            element: <ProductoSaleCreate/>
        },
        {
            path: '/sales-print',
            name: 'sales-print',
            element: <NotaVenta/>
        },
        {
            path: '/ticket-print',
            element: <Ticket/>
        },
        {
            path: '/ventas',
            element: <VentaIndex/>
        },
    ]);
}

export default AppRouting;
