import ClienteActionTypes from "./clientes.types";

//ACTION CREATORS

export const changeClienteStatus = (payload) => ({
    type: ClienteActionTypes.CHANGE_STATUS_CLIENTE,
    payload,
});

export const getClientes = (payload) => ({
    type: ClienteActionTypes.GET_CLIENTES,
    payload,
});

export const getCliente = (payload) => ({
    type: ClienteActionTypes.GET_CLIENTE,
    payload,
});

export const storeCliente = (payload) => ({
    type: ClienteActionTypes.STORE_CLIENTE,
    payload,
});

export const updateCliente = (payload) => ({
    type: ClienteActionTypes.UPDATE_CLIENTE,
    payload,
});

export const deleteCliente = (payload) => ({
    type: ClienteActionTypes.DELETE_CLIENTE,
    payload,
});
