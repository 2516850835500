import {
    getCliente,
    changeClienteStatus,
    getClientes,
    storeCliente,
    updateCliente,
    deleteCliente
} from "./clientes.actions";
import {
    deleteClienteService,
    getClienteService,
    getClientesService,
    storeClienteService,
    updateClienteService
} from "../../services/cliente.service";

export const getClientesThunk = () => {
    return async (dispatch) => {
        dispatch(changeClienteStatus('loading'));
        try {
            const data = await getClientesService();
            console.log("data clientes", data);
            if (!data.error) {
                dispatch(getClientes(data));
                dispatch(changeClienteStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const getClienteThunk = (id) => {
    return async (dispatch) => {
        dispatch(changeClienteStatus('loading'));
        try {
            const data = await getClienteService(id);
            console.log("data cliente", data);
            if (!data.error) {
                dispatch(getCliente(data));
                dispatch(changeClienteStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const storeClienteThunk = (obj) => {
    return async (dispatch) => {
        dispatch(changeClienteStatus('loading'));
        try {
            const data = await storeClienteService(obj);
            console.log("data", data);
            if (!data.error) {
                dispatch(storeCliente(data));
                dispatch(changeClienteStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const updateClienteThunk = (obj, id) => {
    return async (dispatch) => {
        dispatch(changeClienteStatus('loading'));
        try {
            const data = await updateClienteService(obj, id);
            console.log("data edit", data);
            if (!data.error) {
                dispatch(updateCliente(data));
                dispatch(changeClienteStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const deleteClienteThunk = (id) => {
    return async (dispatch) => {
        dispatch(changeClienteStatus('loading'));
        try {
            const data = await deleteClienteService(id);
            console.log("data edit", data);
            if (!data.error) {
                dispatch(deleteCliente(data));
                dispatch(changeClienteStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};
