import axios from "axios";

export const getProveedoresService = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/get-proveedores`);
    console.log('PROVEE', data);
    return data;
}

export const getProveedorService = async (id) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/get-proveedor/${id}`);
    return data;
}

export const storeProveedorService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/store-proveedor`, obj);
    return data;
}

export const updateProveedorService = async (obj, id) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/update-proveedor/${id}`, obj);
    return data;
}

export const deleteProveedorService = async (id) => {
    const { data } = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/delete-proveedor/${id}`);
    return data;
}
