const ClienteActionTypes = {
  CHANGE_STATUS_CLIENTE: "CHANGE_STATUS_CLIENTE",
  GET_CLIENTES: "GET_CLIENTES",
  GET_CLIENTE: "GET_CLIENTE",
  STORE_CLIENTE: "STORE_CLIENTE",
  UPDATE_CLIENTE: "UPDATE_CLIENTE",
  DELETE_CLIENTE: "DELETE_CLIENTE",
};

export default ClienteActionTypes;
