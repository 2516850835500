import axios from "axios";

export const getPagosService = async (idlote) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/get-pagos/${idlote}`);
    return data;
}

export const getPagoService = async (id) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/get-pago/${id}`);
    return data;
}

export const storePagoService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/store-pago`, obj);
    return data;
}

export const updatePagoService = async (obj, id) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/update-pago/${id}`, obj);
    return data;
}

export const deletePagoService = async (id) => {
    const { data } = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/delete-pago/${id}`);
    return data;
}
