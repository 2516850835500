export function toFormData(formValue) {
  const formData = new FormData();
  for (const key of Object.keys(formValue)) {
    if (formValue[key] instanceof Array) {
      formValue[key] = JSON.stringify(formValue[key]);
    }
    /*if (formValue[key] instanceof Object) {
      formValue[key] = JSON.stringify(formValue[key]);
    }*/
    const value = formValue[key];
    formData.append(key, value);
  }
  return formData;
}
