const LoteActionTypes = {
  CHANGE_STATUS_LOTE: "CHANGE_STATUS_LOTE",
  GET_LOTES: "GET_LOTES",
  GET_LOTE: "GET_LOTE",
  STORE_LOTE: "STORE_LOTE",
  UPDATE_LOTE: "UPDATE_LOTE",
  DELETE_LOTE: "DELETE_LOTE",
};

export default LoteActionTypes;
