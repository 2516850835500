import LoteActionTypes from "./lotes.types";

const INITIAL_STATE = {
    lotes: [],
    lote: null,
    status: 'initial',
};

const lotesReducer = (state = INITIAL_STATE, action) => {
    console.log("PL", action);
    switch (action.type) {
        case LoteActionTypes.CHANGE_STATUS_LOTE:
            return {...state, status: action.payload};
        case LoteActionTypes.GET_LOTES:
            return {...state, lotes: action.payload};
        case LoteActionTypes.GET_LOTE:
            return {...state, lote: action.payload};
        case LoteActionTypes.STORE_LOTE:
            return {...state, lote: action.payload};
        case LoteActionTypes.UPDATE_LOTE:
            return {...state, lote: action.payload};
        case LoteActionTypes.DELETE_LOTE:
            return {...state, lote: action.payload};
        default:
            return state;
    }
};


export default lotesReducer;
