import LoteActionTypes from "./lotes.types";

//ACTION CREATORS

export const changeLoteStatus = (payload) => ({
    type: LoteActionTypes.CHANGE_STATUS_LOTE,
    payload,
});

export const getLotes = (payload) => ({
    type: LoteActionTypes.GET_LOTES,
    payload,
});

export const getLote = (payload) => ({
    type: LoteActionTypes.GET_LOTE,
    payload,
});

export const storeLote = (payload) => ({
    type: LoteActionTypes.STORE_LOTE,
    payload,
});

export const updateLote = (payload) => ({
    type: LoteActionTypes.UPDATE_LOTE,
    payload,
});

export const deleteLote = (payload) => ({
    type: LoteActionTypes.DELETE_LOTE,
    payload,
});
