import ProveedorActionTypes from "./proveedores.types";

//ACTION CREATORS

export const changeProveedorStatus = (payload) => ({
    type: ProveedorActionTypes.CHANGE_STATUS_PROVEEDOR,
    payload,
});

export const getProveedores = (payload) => ({
    type: ProveedorActionTypes.GET_PROVEEDORES,
    payload,
});

export const getProveedor = (payload) => ({
    type: ProveedorActionTypes.GET_PROVEEDOR,
    payload,
});

export const storeProveedor = (payload) => ({
    type: ProveedorActionTypes.STORE_PROVEEDOR,
    payload,
});

export const updateProveedor = (payload) => ({
    type: ProveedorActionTypes.UPDATE_PROVEEDOR,
    payload,
});

export const deleteProveedor = (payload) => ({
    type: ProveedorActionTypes.DELETE_PROVEEDOR,
    payload,
});
