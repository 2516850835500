import {Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel} from "@mui/material";
import {useField, useFormikContext} from "formik";

const CheckFieldWrapper = ({
                               name,
                               label,
                               legend,
                               changeValue,
                               ...otherProps
                           }) => {
    const {setFieldValue} = useFormikContext();
    const [field, meta] = useField(name);

    const handleChange = e => {
        const {checked} = e.target;
        setFieldValue(name, checked);
        changeValue(checked);
    }

    const config = {
        ...field,
        onChange: handleChange
    }

    const configControl = {};

    if (meta && meta.touched && meta.error) {
        configControl.error = true;
        // configControl.helperText = meta.error;
    }

    return (
        <FormControl {...configControl}>
            <FormLabel component="legend">{legend}</FormLabel>
            <FormGroup>
                <FormControlLabel control={<Checkbox {...config}/>}
                                  label={label}/>
            </FormGroup>
        </FormControl>
    )
}

export default CheckFieldWrapper;
