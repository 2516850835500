import PagoActionTypes from "./pagos.types";

//ACTION CREATORS

export const changePagoStatus = (payload) => ({
    type: PagoActionTypes.CHANGE_STATUS_PAGO,
    payload,
});

export const getPagos = (payload) => ({
    type: PagoActionTypes.GET_PAGOS,
    payload,
});

export const getPago = (payload) => ({
    type: PagoActionTypes.GET_PAGO,
    payload,
});

export const storePago = (payload) => ({
    type: PagoActionTypes.STORE_PAGO,
    payload,
});

export const updatePago = (payload) => ({
    type: PagoActionTypes.UPDATE_PAGO,
    payload,
});

export const deletePago = (payload) => ({
    type: PagoActionTypes.DELETE_PAGO,
    payload,
});
