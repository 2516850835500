import axios from "axios";

export const getMarcasService = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/get-marcas`);
    return data;
}

export const getMarcaService = async (id) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/get-marca/${id}`);
    return data;
}

export const storeMarcaService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/store-marca`, obj);
    return data;
}

export const updateMarcaService = async (obj, id) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/update-marca/${id}`, obj);
    return data;
}

export const deleteMarcaService = async (id) => {
    const { data } = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/delete-marca/${id}`);
    return data;
}
