import {Card, CardContent, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import TextFieldWrapper from "../../layouts/forms/text-field-wrapper";
import ButtonWrapper from "../../layouts/forms/button-wrapper";
import {Component} from "react";
import {getMedidasThunk, getMedidaThunk} from "../../../redux/medidas/medidas.thunk";
import {connect} from "react-redux";
import {getProductosThunk, getProductoThunk, updateProductoThunk} from "../../../redux/productos/productos.thunk";
import AutocompleteFieldWrapper from "../../layouts/forms/autocomplete-field-wrapper";
import {getMarcasThunk, getMarcaThunk} from "../../../redux/marcas/marcas.thunk";
import {Add} from "@mui/icons-material";


class ProductoEditDialog extends Component {

    state = {
        img: null,
        file: null,
        openMarca: false,
        openMedida: false,
        marca: null,
        medida: null
    }

    INITIAL_FROM_STATE = {
        nombre: this.props.producto.nombre,
        marca_id: '',
        marca: '',
        descripcion: this.props.producto.descripcion,
        medida_id: '',
        medida: '',
    };

    FORM_VALIDATION = Yup.object().shape({
        nombre: Yup.string().required('El nombre es requerido'),
        // marca_id: '',
        // descripcion: Yup.string().required('La descripcion es requerida'),
        // foto: '',
        medida: Yup.mixed().required('La medida es requerida'),
    });

    async componentDidMount() {
        const {producto} = this.props;

        await this.props.getMedidas();
        await this.props.getMarcas();

        await this.props.getMedida(producto.medida_id);

        if (producto.marca_id) {
            await this.props.getMarca(producto.marca_id);
        }

        this.INITIAL_FROM_STATE.medida = this.props.medida;
        this.INITIAL_FROM_STATE.marca = this.props.marca ? this.props.marca : null;

        this.setMedida(this.props.medida);
        this.setMarca(this.props.marca);
    }

    onUpdate = async (values) => {
        console.log(values);
        values.medida_id = values.medida.id;
        values.medida = '';
        if (values.marca !== '') {
            values.marca_id = values.marca.id;
            values.marca = '';
        }
        await this.props.updateProducto(values, this.props.producto.id);
        await this.props.getProductos();
    }

    onAddMarca = () => {
        this.setState({openMarca: true});
    }

    onCloseMarca = async () => {
        this.setState({openMarca: false});
    }

    onAddMedida = () => {
        this.setState({openMedida: true});
    }

    onCloseMedida = async () => {
        this.setState({openMedida: false});
    }

    setMarca = async (item) => {
        this.setState({marca: item});
    }

    setMedida = async (item) => {
        this.setState({medida: item});
    }

    render() {
        const {medidas, statusMedida, statusProducto, marcas, statusMarca, open, onClose} = this.props;
        return (
            <Dialog
                fullWidth={false}
                open={open}
                onClose={() => onClose()}>
                <DialogTitle>Actualizar Producto</DialogTitle>
                <DialogContent>
                    {statusProducto === 'loading' ?
                        <div className="text-center">
                            <CircularProgress/>
                        </div> :
                        <Card>
                            <CardContent>
                                <Formik initialValues={this.INITIAL_FROM_STATE}
                                        validationSchema={this.FORM_VALIDATION}
                                        onSubmit={values => this.onUpdate(values)}>
                                    <Form>
                                        <div className="my-3">
                                            <TextFieldWrapper name="nombre" label="Nombre"/>
                                        </div>
                                        {statusMarca === 'loading' ?
                                            <div className="text-center">
                                                <CircularProgress/>
                                            </div> :
                                            <div className="my-3 position-relative">
                                                <AutocompleteFieldWrapper name="marca"
                                                                          label="Marca"
                                                                          setItem={this.setMarca}
                                                                          labelValue="nombre"
                                                                          options={marcas}/>
                                                {/*<Select name="marca_id" options={marcas} valueKey="id" labelKey="nombre"
                                                onChange={() => console.log('change')}
                                                onMenuOpen={() => console.log('open')}
                                                onMenuClose={() => console.log('close')}/>*/}
                                                <div className="btn-top-right">
                                                    <IconButton className="bg-success text-white p-1"
                                                                onClick={() => this.onAddMarca()}>
                                                        <Add fontSize="small"/>
                                                    </IconButton>
                                                </div>
                                            </div>}
                                        {statusMedida === 'loading' ?
                                            <div className="text-center">
                                                <CircularProgress/>
                                            </div> :
                                            <div className="my-3 position-relative">
                                                <AutocompleteFieldWrapper name="medida"
                                                                          label="Medida de venta"
                                                                          setItem={this.setMedida}
                                                                          labelValue="nombre"
                                                                          options={medidas}/>
                                                {/*<Select name="medida_id" options={medidas} valueKey="id" labelKey="nombre"
                                                onMenuOpen={() => console.log('open')}
                                                onMenuClose={() => console.log('close')}/>*/}
                                                <div className="btn-top-right">
                                                    <IconButton className="bg-success text-white p-1"
                                                                onClick={() => this.onAddMedida()}>
                                                        <Add fontSize="small"/>
                                                    </IconButton>
                                                </div>
                                            </div>}
                                        <div className="my-3">
                                            <TextFieldWrapper name="descripcion" label="Descripcion" multiline={true}
                                                              rows={3}/>
                                        </div>
                                        <div className="my-3">
                                            <ButtonWrapper>ACTUALIZAR</ButtonWrapper>
                                        </div>
                                    </Form>
                                </Formik>
                            </CardContent>
                        </Card>}
                </DialogContent>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        statusProducto: state.productos.status,

        marcas: state.marcas.marcas,
        marca: state.marcas.marca,
        statusMarca: state.marcas.status,

        medidas: state.medidas.medidas,
        medida: state.medidas.medida,
        statusMedida: state.medidas.status,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMedidas: () => dispatch(getMedidasThunk()),
        getMarcas: () => dispatch(getMarcasThunk()),
        getProductos: () => dispatch(getProductosThunk()),


        getProducto: (id) => dispatch(getProductoThunk(id)),
        getMedida: (id) => dispatch(getMedidaThunk(id)),
        getMarca: (id) => dispatch(getMarcaThunk(id)),

        updateProducto: (obj, id) => dispatch(updateProductoThunk(obj, id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductoEditDialog);
