const ProveedorActionTypes = {
  CHANGE_STATUS_PROVEEDOR: "CHANGE_STATUS_PROVEEDOR",
  GET_PROVEEDORES: "GET_PROVEEDORES",
  GET_PROVEEDOR: "GET_PROVEEDOR",
  STORE_PROVEEDOR: "STORE_PROVEEDOR",
  UPDATE_PROVEEDOR: "UPDATE_PROVEEDOR",
  DELETE_PROVEEDOR: "DELETE_PROVEEDOR",
};

export default ProveedorActionTypes;
