import {Avatar, Card, CardActions, CardContent, CardHeader, IconButton, Tooltip, Typography} from "@mui/material";
import {
    AccountBalance,
    AddCircle, CameraAlt,
    CurrencyExchange,
    DesignServices, Edit,
    Image,
    MoreVert,
    Paid, PostAdd,
    Tag
} from "@mui/icons-material";
import PrecioCreateDialog from "../precio/precio-create/precio-create-dialog";
import {useState} from "react";
import PhotoEdit from "../photo-edit/photo-edit";
import {loadFile} from "../../../helpers/files";
import LoteEditDialog from "../../lote/lote-edit/lote-edit-dialog";
import ProductoEditDialog from "../producto-edit/producto-edit-dialog";
import {addProductoPrint, addProductoSale} from "../../../redux/productos/productos.actions";
import {connect} from "react-redux";
import {toastr} from "react-redux-toastr";
import PhotoCameraEdit from "../photo-camera-edit/photo-edit";

const ProductoItem = (props) => {
    const {item} = props;
    const [openPrecio, setOpenPrecio] = useState(false);
    const [openPhoto, setOpenPhoto] = useState(false);
    const [openTakePhoto, setOpenTakePhoto] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const onAddPrecio = () => {
        setOpenPrecio(true);
    }

    const onClosePrecio = async () => {
        setOpenPrecio(false);
    }

    const onEditPhoto = () => {
        setOpenPhoto(true);
    }

    const onTakePhoto = () => {
        setOpenTakePhoto(true);
    }

    const onCloseTakePhoto = () => {
        setOpenTakePhoto(false);
    }

    const onEdit = () => {
        setOpenEdit(true);
    }

    const onCloseEdit = async () => {
        setOpenEdit(false);
    }

    const onClosePhoto = async () => {
        setOpenPhoto(false);
    }

    const onPushPrint = () => {
        if(!props.productosPrint.find((x) => { return x.producto.id === item.id})) {
            props.addProductoPrint([...props.productosPrint, {
                producto: item
            }]);
            toastr.success('Agregado exitosamente para imprimir')
        } else {
            toastr.warning('Ya esta agregado')
        }
    }

    return (
        <Card className="h-100 position-relative">
            <CardHeader
                avatar={
                    <Avatar
                            alt={item.nombre}
                            src={loadFile(item.foto)}/>
                }
                action={
                    <IconButton aria-label="settings">
                        <MoreVert/>
                    </IconButton>
                }
                title={item.nombre}
                subheader={item.marca || 'Sin marca'}/>
            <CardContent>
                <Typography variant="body2" color="text.secondary" className="d-flex justify-content-between my-3 text-description">
                    <b><em>{item.descripcion || 'Sin descripción'}</em></b>
                </Typography>
                <Typography variant="body2" color="text.secondary" className="d-flex justify-content-between my-2">
                    <Tooltip title="Marca">
                        <AccountBalance/>
                    </Tooltip>
                    <b>{item.marca || 'SIN MARCA'}</b>
                </Typography>
                <Typography variant="body2" color="text.secondary"
                            className="d-flex justify-content-between align-items-center text-success my-2">
                    <Tooltip title="Precio Venta">
                        <Paid/>
                    </Tooltip>
                    {item.precio ? <b>{item.precio} Bs.</b> :
                        <span>
                        <em>Añadir precio</em>
                        <IconButton className="text-success" onClick={() => onAddPrecio()}>
                            <AddCircle/>
                        </IconButton>
                    </span>}
                </Typography>
                <Typography variant="body2" color="text.secondary" className="d-flex justify-content-between my-2">
                    <Tooltip title="Medida">
                        <DesignServices/>
                    </Tooltip>
                    <b>{item.medida}</b>
                </Typography>
                <Typography variant="h5" color="text.secondary"
                            className="d-flex justify-content-between text-primary my-2">
                    <Tooltip title="Cantidad disponible">
                        <Tag/>
                    </Tooltip>
                    <b>
                        {item.cantidad_disponible}
                        <small>{` [${item.medida}'s]`}</small>
                    </b>
                </Typography>
                {openPrecio ? <PrecioCreateDialog open={openPrecio} producto_id={item.id} onClose={onClosePrecio}/> : null}
                {openPhoto ? <PhotoEdit producto={item} open={openPhoto} onClose={onClosePhoto}/> : null}
                {openTakePhoto ? <PhotoCameraEdit producto={item} open={openTakePhoto} onClose={onCloseTakePhoto}/> : null}
                {openEdit ? <ProductoEditDialog open={openEdit} producto={item} onClose={onCloseEdit}/> : null}
            </CardContent>
            <CardActions disableSpacing className="justify-content-end">
                <IconButton aria-label="Imprimir nombre" onClick={() => onPushPrint()} className="text-primary">
                    <PostAdd/>
                </IconButton>
                <IconButton aria-label="Editar" onClick={() => onEdit()} className="text-success">
                    <Edit/>
                </IconButton>
                {item.precio ?
                    <IconButton aria-label="actualizar precio" onClick={() => onAddPrecio()}>
                        <CurrencyExchange/>
                    </IconButton> : null}

                <IconButton aria-label="actualizar foto" onClick={() => onEditPhoto()} className="text-success">
                    <Image/>
                </IconButton>
                <IconButton aria-label="Tomar foto" onClick={() => onTakePhoto()} className="text-primary">
                    <CameraAlt/>
                </IconButton>
            </CardActions>
        </Card>
    );
}
const mapStateToProps = (state) => {
    return {
        productosPrint: state.productos.productosPrint,
    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        addProductoPrint: (producto) => dispatch(addProductoPrint(producto)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ProductoItem);
