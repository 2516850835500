import VentaActionTypes from "./ventas.types";

const INITIAL_STATE = {
    ventas: [],
    venta: null,
    status: 'initial',
};

const ventasReducer = (state = INITIAL_STATE, action) => {
    console.log("PL", action);
    switch (action.type) {
        case VentaActionTypes.CHANGE_STATUS_VENTA:
            return {...state, status: action.payload};
        case VentaActionTypes.GET_VENTAS:
            return {...state, ventas: action.payload};
        case VentaActionTypes.GET_VENTA:
            return {...state, venta: action.payload};
        case VentaActionTypes.STORE_VENTA:
            return {...state, venta: action.payload};
        case VentaActionTypes.UPDATE_VENTA:
            return {...state, venta: action.payload};
        case VentaActionTypes.DELETE_VENTA:
            return {...state, venta: action.payload};
        default:
            return state;
    }
};


export default ventasReducer;
