import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import {sizeString} from "../../../helpers/forms";
import {Close} from "@mui/icons-material";

const Input = styled('input')({
    display: 'none',
});

const FileFieldWrapper = (props) => {
    const {name, accept, onChangeImg, file, onDelete} = props;
    return (
        <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
            <label htmlFor="contained-button-file">
                <Input onChange={onChangeImg} name={name} accept={accept ? accept : '*'} id="contained-button-file" type="file" />
                <Button variant="contained" component="span">
                    Cargar
                </Button>
                <small className="ms-2">{file? sizeString(file.name, 25) : ''}</small>
            </label>
            {file ? <IconButton onClick={onDelete}>
                <Close/>
            </IconButton> : null }
        </Stack>
    );
}

export default FileFieldWrapper;
