import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as PropTypes from "prop-types";
import TextFieldWrapper from "../../layouts/forms/text-field-wrapper";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import ButtonWrapper from "../../layouts/forms/button-wrapper";
import {getClientesThunk, storeClienteThunk} from "../../../redux/clientes/clientes.thunk";
import {connect} from "react-redux";
import {CircularProgress} from "@mui/material";

function Fragment(props) {
    return null;
}

Fragment.propTypes = {children: PropTypes.node};
const ClienteCreateDialog = (props) => {

    const {open, onClose} = props;

    const INITIAL_FROM_STATE = {
        nombre: '',
        nit: '',
        celular: '',
        direccion: ''
    };

    const FORM_VALIDATION = Yup.object().shape({
        nombre: Yup.string().required('El nombre es requerido'),
        nit: Yup.string().required('El nit es requerido'),
        //celular: Yup.string().required('El celular es requerido'),
    });

    const handleClose = () => {
        onClose();
    };

    const onStore = async (values) => {
        console.log(values);
        await props.storeCliente(values);
        onClose(props.cliente);
        await props.getClientes();
    }

    return (
        <Dialog
            fullWidth={false}
            open={open}
            onClose={handleClose}>
            <DialogTitle>Registrar Cliente</DialogTitle>
            <DialogContent>
                {props.statusCliente === 'loading' ?
                    <div className="text-center">
                        <CircularProgress/>
                        <p>Registrando...</p>
                    </div>
                    : <Formik initialValues={INITIAL_FROM_STATE}
                              validationSchema={FORM_VALIDATION}
                              onSubmit={values => onStore(values)}>
                        <Form>
                            <div className="my-3">
                                <TextFieldWrapper name="nombre" label="Nombre"/>
                            </div>
                            <div className="my-3">
                                <TextFieldWrapper name="nit" label="NIT" type="text"/>
                            </div>
                            <div className="my-3">
                                <TextFieldWrapper name="celular" label="Celular" type="number"/>
                            </div>
                            <div className="my-3">
                                <TextFieldWrapper name="direccion" label="Direccion" multiline={true}
                                                  rows={3}/>
                            </div>
                            <div className="my-3">
                                <ButtonWrapper>REGISTRAR</ButtonWrapper>
                            </div>
                        </Form>
                    </Formik>}
            </DialogContent>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        cliente: state.clientes.cliente,
        statusCliente: state.clientes.status,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getClientes: () => dispatch(getClientesThunk()),
        storeCliente: (obj) => dispatch(storeClienteThunk(obj)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClienteCreateDialog);
