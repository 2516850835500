import './App.css';
import './scroll.css';
import {Box, Container} from "@mui/material";
import AppRouting from "./app-routing";
import Navbar from "./components/layouts/navbar/navbar";
import moment from "moment";

function App() {
    moment.locale(['es']);
    console.log("LOCALE", moment.locale())
    return (
        <div className="min-vh-100 bg-light">
            <Navbar/>
            <Box className="h-100" sx={{ my: 8 }}>
                <Container className="mt-2 mt-md-5">
                    <div className="mx-md-5">
                        <AppRouting/>
                    </div>
                </Container>
            </Box>
        </div>
    );
}

export default App;
