import {
    getPrecio,
    changePrecioStatus,
    getPrecios,
    storePrecio,
    updatePrecio,
    deletePrecio
} from "./precios.actions";
import {
    deletePrecioService,
    getPrecioService,
    getPreciosService,
    storePrecioService,
    updatePrecioService
} from "../../services/precio.service";

export const getPreciosThunk = (id) => {
    return async (dispatch) => {
        dispatch(changePrecioStatus('loading'));
        try {
            const data = await getPreciosService(id);
            console.log("data precios", data);
            if (!data.error) {
                dispatch(getPrecios(data));
                dispatch(changePrecioStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const getPrecioThunk = (id) => {
    return async (dispatch) => {
        dispatch(changePrecioStatus('loading'));
        try {
            const data = await getPrecioService(id);
            console.log("data precio", data);
            if (!data.error) {
                dispatch(getPrecio(data));
                dispatch(changePrecioStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const storePrecioThunk = (obj) => {
    return async (dispatch) => {
        dispatch(changePrecioStatus('loading'));
        try {
            const data = await storePrecioService(obj);
            console.log("data precios", data);
            if (!data.error) {
                dispatch(storePrecio(data));
                dispatch(changePrecioStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const updatePrecioThunk = (obj, id) => {
    return async (dispatch) => {
        dispatch(changePrecioStatus('loading'));
        try {
            const data = await updatePrecioService(obj, id);
            console.log("data edit", data);
            if (!data.error) {
                dispatch(updatePrecio(data));
                dispatch(changePrecioStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const deletePrecioThunk = (id) => {
    return async (dispatch) => {
        dispatch(changePrecioStatus('loading'));
        try {
            const data = await deletePrecioService(id);
            console.log("data delete precio", data);
            if (!data.error) {
                dispatch(deletePrecio(data));
                dispatch(changePrecioStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};
