import ProductoActionTypes from "./productos.types";

const INITIAL_STATE = {
    productos: [],
    productosSale: [],
    productosPrint: [],
    productosFilter: [],
    producto: null,
    status: 'initial',
};

const productosReducer = (state = INITIAL_STATE, action) => {
    console.log("PL", action);
    switch (action.type) {
        case ProductoActionTypes.CHANGE_STATUS_PRODUCTO:
            return {...state, status: action.payload};
        case ProductoActionTypes.GET_PRODUCTOS:
            return {...state, productos: action.payload};
        /*case ProductoActionTypes.GET_PRODUCTOS_PAGINATE:
            return {...state, productos: action.payload};*/
        case ProductoActionTypes.GET_PRODUCTOS_FILTER:
            return {...state, productosFilter: action.payload};
        case ProductoActionTypes.GET_PRODUCTO:
            return {...state, producto: action.payload};
        case ProductoActionTypes.STORE_PRODUCTO:
            return {...state, producto: action.payload};
        case ProductoActionTypes.UPDATE_PRODUCTO:
            return {...state, producto: action.payload};
        case ProductoActionTypes.UPDATE_PHOTO_PRODUCTO:
            return {...state, producto: action.payload};
        case ProductoActionTypes.DELETE_PRODUCTO:
            return {...state, producto: action.payload};
        case ProductoActionTypes.ADD_PRODUCTO_SALE:
            return {
                ...state,
                productosSale: action.payload
            };
        case ProductoActionTypes.RESET_PRODUCTO_SALE:
            return {...state, productosSale: []};
        case ProductoActionTypes.ADD_PRODUCTO_PRINT:
            return {
                ...state,
                productosPrint: action.payload
            };
        case ProductoActionTypes.RESET_PRODUCTO_PRINT:
            return {...state, productosPrint: []};
        default:
            return state;
    }
};


export default productosReducer;
