import './search.css';
import * as React from 'react';
import {useEffect, useState} from 'react';
import SearchIcon from "@mui/icons-material/Search";
import {alpha, styled} from "@mui/material/styles";
import {Autocomplete, Avatar, Box, CircularProgress, TextField} from "@mui/material";
import {loadFile} from "../../../helpers/files";
import {getProductosFilterThunk} from "../../../redux/productos/productos.thunk";
import {connect} from "react-redux";
import {getProductosFilter} from "../../../redux/productos/productos.actions";


const Search = styled('div')(({theme}) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

/*const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));*/

const SearchBar = (props) => {
    const [open, setOpen] = useState(false);
    const loading = open && props.productos.length === 0;
    //const loading = false;

    useEffect(() => {
        let active = true;

        /*if (!loading) {
            return undefined;
        }*/

        (async () => {
            await props.getProductos();
        })();
        /*return () => {
            active = false;
        };*/
    }, [])

    /*useEffect(() => {
        if (!open) {
            props.resetProductos();
        }
    }, [open]);*/

    return (
        <div className="search-content">
            <Search>
                <SearchIconWrapper>
                    <SearchIcon/>
                </SearchIconWrapper>
                {/*<Autocomplete
              disablePortal
              value={'ds'}
              options={['ds', 'ddds']}
              renderInput={
                  (params) =>
                      <StyledInputBase {...params}
                                       placeholder="Buscar Producto…"
                                       inputProps={{'label': 'search'}}/>}
          />*/}
                <Autocomplete
                    disablePortal
                    id="search-auto-complete"
                    open={open}
                    disableCloseOnSelect={true}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) => option.nombre === value.nombre || option.descripcion === value.descripcion}
                    getOptionLabel={(option) => option.nombre + ' ' + option.descripcion}
                    loading={loading}
                    getOptionDisabled={(option) => true}
                    groupBy={(option) => option.nombre[0].toUpperCase()}
                    options={props.productos}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                            <Box className="d-flex">
                            <Avatar
                                alt={option.nombre}
                                src={loadFile(option.foto)} className="me-1"/>
                                <div>
                                    {option.nombre}
                                    <p><small className="text-black-50">{option.descripcion}</small></p>
                                </div>
                            </Box>
                            <div className="ms-1 text-center px-2 bg-light-price">
                                <b className="ms-2 text-primary">
                                    <p className="fs-5 m-0">{option.precio ? `${option.precio}` : 'S/P'}</p>
                                    <span className="price-type">{`[Bs./${option.medida.toLowerCase()}]`}</span>
                                </b>
                            </div>
                            {/*<IconButton><AddShoppingCart/></IconButton>*/}
                        </Box>
                    )}
                    renderInput={(params) =>
                        (<TextField
                                {...params}
                                placeholder="Buscar Producto..."
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                />
                {/*<StyledInputBase
                    placeholder="Buscar Producto…"
                    inputProps={{
                        'label': 'search',
                    }}/>*/}
            </Search>
        </div>
    )
}
const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        productos: state.productos.productosFilter,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getProductos: () => dispatch(getProductosFilterThunk()),
        resetProductos: () => dispatch(getProductosFilter([])),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
