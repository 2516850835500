import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as PropTypes from "prop-types";
import TextFieldWrapper from "../../layouts/forms/text-field-wrapper";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import ButtonWrapper from "../../layouts/forms/button-wrapper";
import {getMarcasThunk, storeMarcaThunk} from "../../../redux/marcas/marcas.thunk";
import {connect} from "react-redux";
import {CircularProgress} from "@mui/material";

function Fragment(props) {
    return null;
}

Fragment.propTypes = {children: PropTypes.node};
const MarcaCreateDialog = (props) => {

    const {open, onClose} = props;

    const INITIAL_FROM_STATE = {
        nombre: '',
        descripcion: '',
    };

    const FORM_VALIDATION = Yup.object().shape({
        nombre: Yup.string().required('El nombre es requerido'),
    });

    const handleClose = () => {
        onClose();
    };

    const onStore = async (values) => {
        console.log(values);
        await props.storeMarca(values);
        onClose(props.marca);
        await props.getMarcas();
    }

    return (
        <Dialog
            fullWidth={false}
            open={open}
            onClose={handleClose}>
            <DialogTitle>Registrar Marca</DialogTitle>
            <DialogContent>
                {props.statusMarca === 'loading' ?
                    <div className="text-center">
                        <CircularProgress/>
                        <p>Registrando...</p>
                    </div>
                    : <Formik initialValues={INITIAL_FROM_STATE}
                              validationSchema={FORM_VALIDATION}
                              onSubmit={values => onStore(values)}>
                        <Form>
                            <div className="my-3">
                                <TextFieldWrapper name="nombre" label="Nombre"/>
                            </div>
                            <div className="my-3">
                                <TextFieldWrapper name="descripcion" label="Descripcion" multiline={true}
                                                  rows={3}/>
                            </div>
                            <div className="my-3">
                                <ButtonWrapper>REGISTRAR</ButtonWrapper>
                            </div>
                        </Form>
                    </Formik>}
            </DialogContent>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        marca: state.marcas.marca,
        statusMarca: state.marcas.status,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMarcas: () => dispatch(getMarcasThunk()),
        storeMarca: (obj) => dispatch(storeMarcaThunk(obj)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MarcaCreateDialog);
