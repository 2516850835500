import {
    getLote,
    changeLoteStatus,
    getLotes,
    storeLote,
    updateLote,
    deleteLote
} from "./lotes.actions";
import {
    deleteLoteService,
    getLoteService,
    getLotesService,
    storeLoteService,
    updateLoteService
} from "../../services/lote.service";

export const getLotesThunk = () => {
    return async (dispatch) => {
        dispatch(changeLoteStatus('loading'));
        try {
            const data = await getLotesService();
            console.log("data lotes", data);
            if (!data.error) {
                dispatch(getLotes(data));
                dispatch(changeLoteStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const getLoteThunk = (id) => {
    return async (dispatch) => {
        dispatch(changeLoteStatus('loading'));
        try {
            const data = await getLoteService(id);
            console.log("data lote", data);
            if (!data.error) {
                dispatch(getLote(data));
                dispatch(changeLoteStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const storeLoteThunk = (obj) => {
    return async (dispatch) => {
        dispatch(changeLoteStatus('loading'));
        try {
            const data = await storeLoteService(obj);
            console.log("data", data);
            if (!data.error) {
                dispatch(storeLote(data));
                dispatch(changeLoteStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const updateLoteThunk = (obj, id) => {
    return async (dispatch) => {
        dispatch(changeLoteStatus('loading'));
        try {
            const data = await updateLoteService(obj, id);
            console.log("data edit", data);
            if (!data.error) {
                dispatch(updateLote(data));
                dispatch(changeLoteStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const deleteLoteThunk = (id) => {
    return async (dispatch) => {
        dispatch(changeLoteStatus('loading'));
        try {
            const data = await deleteLoteService(id);
            console.log("data edit", data);
            if (!data.error) {
                dispatch(deleteLote(data));
                dispatch(changeLoteStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};
