import ClienteActionTypes from "./clientes.types";

const INITIAL_STATE = {
    clientes: [],
    cliente: null,
    status: 'initial',
};

const clientesReducer = (state = INITIAL_STATE, action) => {
    console.log("PL", action);
    switch (action.type) {
        case ClienteActionTypes.CHANGE_STATUS_CLIENTE:
            return {...state, status: action.payload};
        case ClienteActionTypes.GET_CLIENTES:
            return {...state, clientes: action.payload};
        case ClienteActionTypes.GET_CLIENTE:
            return {...state, cliente: action.payload};
        case ClienteActionTypes.STORE_CLIENTE:
            return {...state, cliente: action.payload};
        case ClienteActionTypes.UPDATE_CLIENTE:
            return {...state, cliente: action.payload};
        case ClienteActionTypes.DELETE_CLIENTE:
            return {...state, cliente: action.payload};
        default:
            return state;
    }
};


export default clientesReducer;
