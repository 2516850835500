import {Card, CardContent, CardMedia, CircularProgress, Dialog, DialogContent, IconButton} from "@mui/material";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import ButtonWrapper from "../../layouts/forms/button-wrapper";
import {useEffect, useRef, useState} from "react";
import FileFieldWrapper from "../../layouts/forms/file-field-wrapper";
import {connect} from "react-redux";
import {getProductosThunk, updatePhotoProductoThunk} from "../../../redux/productos/productos.thunk";
import {toFormData} from "../../../helpers/toFormData";
import * as PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import {loadFile} from "../../../helpers/files";
import {Camera, Check, Close} from "@mui/icons-material";

function Fragment(props) {
    return null;
}

Fragment.propTypes = {children: PropTypes.node};
const PhotoCameraEdit = (props) => {
    const {open, onClose} = props;

    const [img, setImg] = useState(loadFile(props.producto.foto));
    const [file, setFile] = useState(null);

    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [capturedImage, setCapturedImage] = useState(null);
    const [stream, setStream] = useState(null);
    const [mirror, setMirror] = useState(true);

    const startCamera = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: { exact: 'environment' } }  });
            console.log("STREEEEM", stream)
            videoRef.current.srcObject = stream;

            videoRef.current.addEventListener('loadedmetadata', () => {
                console.log("DIM", videoRef.current.videoWidth, videoRef.current.videoHeight)
                canvasRef.current.width = videoRef.current.videoWidth;
                canvasRef.current.height = videoRef.current.videoHeight;
            });

            setStream(stream)
        } catch (error) {
            console.error('Error al acceder a la cámara:', error);
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            console.log("STREEEEM", stream)
            videoRef.current.srcObject = stream;
            setMirror(false)
            videoRef.current.addEventListener('loadedmetadata', () => {
                console.log("DIM", videoRef.current.videoWidth, videoRef.current.videoHeight)
                canvasRef.current.width = videoRef.current.videoWidth;
                canvasRef.current.height = videoRef.current.videoHeight;
            });

            setStream(stream)
        }
    };

    useEffect(() => {
        // Acceder a la cámara del dispositivo
        startCamera();

        return () => {
            /*if (videoRef.current && videoRef.current.srcObject) {
                videoRef.current.srcObject.getTracks().forEach(track => track.stop());
            }*/
            stopCamera()
        };
    }, []);

    const stopCamera = () => {
        console.log("you tried stop the camera")
        if (stream) {
            console.log("have stream")

            stream.getTracks().forEach(track => track.stop());
        }
    }

    const capturePhoto = () => {
        const context = canvasRef.current.getContext('2d');

        // Calcular el tamaño del cuadrado
        const squareSize = Math.min(canvasRef.current.width, canvasRef.current.height);

        // Calcular las coordenadas para centrar la imagen
        const offsetX = (canvasRef.current.width - squareSize) / 2;
        const offsetY = (canvasRef.current.height - squareSize) / 2;

        // Ajustar el tamaño del canvas al cuadrado
        canvasRef.current.width = squareSize;
        canvasRef.current.height = squareSize;


        context.drawImage(videoRef.current, offsetX, offsetY, squareSize, squareSize, 0, 0, squareSize, squareSize);
        const imageData = canvasRef.current.toDataURL('image/png');
        setCapturedImage(imageData);
        stopCamera();
    };

    const resetCapturedImage = () => {
        setCapturedImage(null)
        startCamera();
    }

    const INITIAL_FROM_STATE = {
        id: '',
        foto: null,
    }

    const FORM_VALIDATION = Yup.object().shape({
        // foto: '',
    });

    const onChangeImg = (e) => {
        if (e.target.files.length > 0) {
            const currentFile = e.target.files[0];
            setImg(URL.createObjectURL(currentFile));
            setFile(currentFile);
        }
    }

    function base64ToBlob(base64, mimeType) {
        const byteString = atob(base64.split(',')[1]);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            uint8Array[i] = byteString.charCodeAt(i);
        }

        return new Blob([uint8Array], { type: mimeType });
    }

    function blobToFile(blob, fileName) {
        return new File([blob], fileName, { type: blob.type });
    }

    const onDeleteImage = () => {
        setImg(loadFile(props.producto.foto));
        setFile(null);
    }

    const onStore = async (values) => {
        console.log(values);
        const form = toFormData(values);
        const blob = base64ToBlob(capturedImage, 'image/png');
        const fileFromBlob = blobToFile(blob, 'image.png');
        setFile(fileFromBlob);
        form.append('foto', fileFromBlob);
        console.log(form);

        await props.updatePhotoProducto(form, props.producto.id);
        onDeleteImage();

        await props.getProductos();

    }
    return (
        <Dialog
            fullWidth
            open={open}
            onClose={() => {stopCamera(); onClose()}}>
            <DialogTitle>Tomar foto para <b style={{fontSize: '0.8rem'}}>{props.producto.nombre}</b></DialogTitle>
            <DialogContent>

                {props.statusProducto === 'loading' ?
                    <div className="text-center">
                        <CircularProgress/>
                    </div> :
                    <Card>
                        {/*<CardMedia component="img"
                                       className="w-50 mx-auto"
                                       image={userImg}
                                       alt={this.props.user.firstName}/>*/}
                        {!capturedImage && <video ref={videoRef} autoPlay playsInline className="w-100" style={{
                            width: '100%',
                            maxWidth: '100%', // Evita que se expanda más allá del contenedor
                            height: 'auto',
                            // transform: 'rotate(360deg)', // Rotación a 90 grados
                            transform: !mirror ? 'scaleX(-1)' : 'scaleX(1)',
                            objectFit: 'cover' // Asegura que el video cubra todo el elemento
                        }}/>}
                        {capturedImage && (
                            <div>
                                <img src={capturedImage} alt="Capturada" className="w-100" />
                            </div>
                        )}
                        {!capturedImage && <div className="d-flex justify-content-center">
                            <IconButton onClick={capturePhoto}>
                                <Camera/>
                            </IconButton>
                        </div>}
                        {capturedImage && <div className="d-flex justify-content-between">
                            <IconButton className="text-success" onClick={onStore}>
                                <Check/>
                            </IconButton>
                            <IconButton className="text-danger" onClick={resetCapturedImage}>
                                <Close/>
                            </IconButton>
                        </div>}
                        <canvas ref={canvasRef} style={{ display: 'none' }}/>
                    </Card>}
            </DialogContent>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        statusProducto: state.productos.status
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updatePhotoProducto: (obj, id) => dispatch(updatePhotoProductoThunk(obj, id)),
        getProductos: () => dispatch(getProductosThunk()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhotoCameraEdit);
