import {Card, CardContent, CardMedia, CircularProgress, Dialog, DialogContent} from "@mui/material";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import ButtonWrapper from "../../layouts/forms/button-wrapper";
import {useState} from "react";
import FileFieldWrapper from "../../layouts/forms/file-field-wrapper";
import {connect} from "react-redux";
import {getProductosThunk, updatePhotoProductoThunk} from "../../../redux/productos/productos.thunk";
import {toFormData} from "../../../helpers/toFormData";
import * as PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import {loadFile} from "../../../helpers/files";

function Fragment(props) {
    return null;
}

Fragment.propTypes = {children: PropTypes.node};
const PhotoEdit = (props) => {
    const {open, onClose} = props;

    const [img, setImg] = useState(loadFile(props.producto.foto));
    const [file, setFile] = useState(null);

    const INITIAL_FROM_STATE = {
        id: '',
        foto: null,
    }

    const FORM_VALIDATION = Yup.object().shape({
        // foto: '',
    });

    const onChangeImg = (e) => {
        if (e.target.files.length > 0) {
            const currentFile = e.target.files[0];
            setImg(URL.createObjectURL(currentFile));
            setFile(currentFile);
        }
    }

    const onDeleteImage = () => {
        setImg(loadFile(props.producto.foto));
        setFile(null);
    }

    const onStore = async (values) => {
        console.log(values);
        const form = toFormData(values);
        form.append('foto', file);
        console.log(form);

        await props.updatePhotoProducto(form, props.producto.id);
        onDeleteImage();

        await props.getProductos();

    }
    return (
        <Dialog
            fullWidth={false}
            open={open}
            onClose={() => onClose()}>
            <DialogTitle>Actualizar foto</DialogTitle>
            <DialogContent>

                {props.statusProducto === 'loading' ?
                    <div className="text-center">
                        <CircularProgress/>
                    </div> :
                    <Card>
                        {/*<CardMedia component="img"
                                       className="w-50 mx-auto"
                                       image={userImg}
                                       alt={this.props.user.firstName}/>*/}
                        {img ?
                            <label htmlFor="contained-button-file"><CardMedia component="img"
                                                                              image={img}/></label> : null}
                        <CardContent>
                            <p className="text-center"><em><b>{props.producto.nombre}</b></em></p>
                            <p className="text-center text-black-50 text-description"><em><small>{props.producto.descripcion || 'Sin descripción'}</small></em></p>
                            <Formik initialValues={INITIAL_FROM_STATE}
                                    validationSchema={FORM_VALIDATION}
                                    onSubmit={values => onStore(values)}>
                                <Form>
                                    <div className="my-3">
                                        <FileFieldWrapper name="foto" accept="image/*"
                                                          onChangeImg={onChangeImg}
                                                          file={file} onDelete={onDeleteImage}/>
                                    </div>
                                    <div className="my-3">
                                        <ButtonWrapper>ACTUALIZAR</ButtonWrapper>
                                    </div>
                                </Form>
                            </Formik>
                        </CardContent>
                    </Card>}
            </DialogContent>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        statusProducto: state.productos.status
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updatePhotoProducto: (obj, id) => dispatch(updatePhotoProductoThunk(obj, id)),
        getProductos: () => dispatch(getProductosThunk()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhotoEdit);
