import PrecioActionTypes from "./precios.types";

const INITIAL_STATE = {
    precios: [],
    precio: null,
    status: 'initial',
};

const preciosReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PrecioActionTypes.CHANGE_STATUS_PRECIO:
            return {...state, status: action.payload};
        case PrecioActionTypes.GET_PRECIOS:
            return {...state, precios: action.payload};
        case PrecioActionTypes.GET_PRECIO:
            return {...state, precio: action.payload};
        case PrecioActionTypes.STORE_PRECIO:
            return {...state, precio: action.payload};
        case PrecioActionTypes.UPDATE_PRECIO:
            return {...state, precio: action.payload};
        case PrecioActionTypes.DELETE_PRECIO:
            return {...state, precio: action.payload};
        default:
            return state;
    }
};


export default preciosReducer;
