const PagoActionTypes = {
  CHANGE_STATUS_PAGO: "CHANGE_STATUS_PAGO",
  GET_PAGOS: "GET_PAGOS",
  GET_PAGO: "GET_PAGO",
  STORE_PAGO: "STORE_PAGO",
  UPDATE_PAGO: "UPDATE_PAGO",
  DELETE_PAGO: "DELETE_PAGO",
};

export default PagoActionTypes;
