import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as PropTypes from "prop-types";
import TextFieldWrapper from "../../../layouts/forms/text-field-wrapper";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import ButtonWrapper from "../../../layouts/forms/button-wrapper";
import {storePrecioThunk} from "../../../../redux/precios/precios.thunk";
import {connect} from "react-redux";
import {CircularProgress} from "@mui/material";
import {getProductosThunk} from "../../../../redux/productos/productos.thunk";

function Fragment(props) {
    return null;
}

Fragment.propTypes = {children: PropTypes.node};
const PrecioCreateDialog = (props) => {

    const {open, onClose, producto_id} = props;

    const INITIAL_FROM_STATE = {
        valor: '',
    };

    const FORM_VALIDATION = Yup.object().shape({
        valor: Yup.string().required('El valor es requerido'),
    });

    const handleClose = () => {
        onClose();
    };

    const onStore = async (values) => {
        console.log(values);
        values.producto_id = producto_id;
        await props.storePrecio(values);
        onClose(props.precio);
        await props.getProductos();
    }

    return (
        <Dialog
            fullWidth={false}
            open={open}
            onClose={handleClose}>
            <DialogTitle>Actualizar Precio</DialogTitle>
            <DialogContent>
                {props.statusPrecio === 'loading' ?
                    <div className="text-center">
                        <CircularProgress/>
                        <p>Registrando...</p>
                    </div>
                    : <Formik initialValues={INITIAL_FROM_STATE}
                              validationSchema={FORM_VALIDATION}
                              onSubmit={values => onStore(values)}>
                        <Form>
                            <div className="my-3">
                                <TextFieldWrapper name="valor" label="Precio" type="number" step="0.1"/>
                            </div>
                            <div className="my-3">
                                <ButtonWrapper>ACTUALIZAR</ButtonWrapper>
                            </div>
                        </Form>
                    </Formik>}
            </DialogContent>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        precio: state.precios.precio,
        statusPrecio: state.precios.status,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProductos: () => dispatch(getProductosThunk()),
        storePrecio: (obj) => dispatch(storePrecioThunk(obj)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrecioCreateDialog);
