import {Avatar, Badge, IconButton, ListItemAvatar, ListItemText, Menu, Typography} from "@mui/material";
import {Delete, ShoppingCart, ShoppingCartCheckout} from "@mui/icons-material";
import {connect} from "react-redux";
import {useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import {loadFile} from "../../../helpers/files";
import {addProductoSale} from "../../../redux/productos/productos.actions";
import './cart-sale.css';
import {useNavigate} from "react-router-dom";

const CartSale = (props) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const deleteProducto = (item) => {
        const newProductos = props.productosSale.filter((prod) => {
            return item.producto.id !== prod.producto.id;
        })
        props.updateProductoSale(newProductos);
    }

    const getTotal = () => {
        let total = 0;
        props.productosSale.map((item) => {
            total += item.cantidad * item.producto.precio;
        })
        return total;
    }
    return (
        <div>
            <IconButton size="large" aria-label="show 4 new mails"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={() => {
                            if (props.productosSale.length > 0)
                                navigate('/sales-create')
                        }}
                        color="inherit">
                <Badge badgeContent={props.productosSale.length > 0 ? props.productosSale.length : null } color="error">
                    <ShoppingCart/>
                </Badge>
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open && props.productosSale.length > 0}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                {props.productosSale.map((item, index) =>
                    <MenuItem key={index}>
                        <ListItemAvatar>
                            <Avatar
                                alt={item.producto.nombre}
                                src={loadFile(item.producto.foto)}/>
                        </ListItemAvatar>
                        <ListItemText primary={<span>{item.cantidad}<small className="ms-1 sm-text">{item.producto.medida}</small></span>} className="me-2"/>
                        <ListItemText secondary={<small>{item.producto.nombre}</small>}/>
                        <Typography variant="body2" color="text.primary" className="ms-3">
                            <span className="">{`${item.producto.precio * item.cantidad} Bs.`}</span>
                            <IconButton className="ms-2" onClick={() => deleteProducto(item)}>
                                <Delete color="error"/>
                            </IconButton>
                        </Typography>

                    </MenuItem>)}
                <MenuItem>
                    <IconButton className="me-2" onClick={() => navigate('/sales-create')}>
                        <ShoppingCartCheckout color="success"/>
                    </IconButton>
                    <ListItemText primary={`TOTAL: `}/>
                    <Typography variant="h5" color="text.primary" className="ms-3">
                        {`${getTotal()} Bs.`}
                    </Typography>
                </MenuItem>
            </Menu>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        productosSale: state.productos.productosSale,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateProductoSale: (producto) => dispatch(addProductoSale(producto)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(CartSale);
