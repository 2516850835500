import axios from "axios";

export const getClientesService = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/get-clientes`);
    return data;
}

export const getClienteService = async (id) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/get-cliente/${id}`);
    return data;
}

export const storeClienteService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/store-cliente`, obj);
    return data;
}

export const updateClienteService = async (obj, id) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/update-cliente/${id}`, obj);
    return data;
}

export const deleteClienteService = async (id) => {
    const { data } = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/delete-cliente/${id}`);
    return data;
}
