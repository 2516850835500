import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as PropTypes from "prop-types";
import TextFieldWrapper from "../../../layouts/forms/text-field-wrapper";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import ButtonWrapper from "../../../layouts/forms/button-wrapper";
import {storePagoThunk} from "../../../../redux/pagos/pagos.thunk";
import {connect} from "react-redux";
import {CircularProgress} from "@mui/material";
import {getLotesThunk} from "../../../../redux/lotes/lotes.thunk";
import DateTimeFieldWrapper from "../../../layouts/forms/date-time-field-wrapper";
import FileFieldWrapper from "../../../layouts/forms/file-field-wrapper";
import {useState} from "react";
import {toFormData} from "../../../../helpers/toFormData";

function Fragment(props) {
    return null;
}

Fragment.propTypes = {children: PropTypes.node};
const PagoCreateDialog = (props) => {

    const {open, onClose, lote_id, cancelado, total} = props;

    const [file, setFile] = useState(null);

    const INITIAL_FROM_STATE = {
        fecha: '',
        monto: '',
        comprobante: ''
    };

    const FORM_VALIDATION = Yup.object().shape({
        fecha: Yup.string().required('La fecha es requerida'),
        monto: Yup.string().required('El monto es requerido'),
        //comprobante: Yup.string().required('El comprobante es requerido'),
    });

    const handleClose = () => {
        onClose();
    };

    const onStore = async (values) => {
        console.log(values);
        if (file !== null) {
            values.lote_id = lote_id;
            const form = toFormData(values);
            form.append('comprobante', file);
            await props.storePago(form);
            onClose(props.pago);
            await props.getLotes();
        } else {
            alert('Por favor introduzca un comprobante');
        }
    }

    const onChangeImg = (e) => {
        if (e.target.files.length > 0) {
            const currentFile = e.target.files[0];
            setFile(currentFile);
        }
    }

    const onDeleteImage = () => {
        setFile(null);
    }

    return (
        <Dialog
            fullWidth={false}
            open={open}
            onClose={handleClose}>
            <DialogTitle>Actualizar Pago</DialogTitle>
            <DialogContent>
                <p className="text-end">
                    <b><small>Total: {total} Bs.</small></b>
                </p>

                <p className="text-end text-success">
                    <b><small>Cancelado: {cancelado} Bs.</small></b>
                </p>
                {props.statusPago === 'loading' ?
                    <div className="text-center">
                        <CircularProgress/>
                        <p>Registrando...</p>
                    </div>
                    : <Formik initialValues={INITIAL_FROM_STATE}
                              validationSchema={FORM_VALIDATION}
                              onSubmit={values => onStore(values)}>
                        <Form>
                            <div className="my-3">
                                <DateTimeFieldWrapper name="fecha" label="Fecha de registro"/>
                            </div>
                            <div className="my-3">
                                <TextFieldWrapper name="monto" label="Pago" type="number"/>
                            </div>
                            <div className="my-3">
                                <FileFieldWrapper name="comprobante" accept="*/*"
                                                  onChangeImg={onChangeImg}
                                                  file={file} onDelete={onDeleteImage}/>
                            </div>
                            <div className="my-3">
                                <ButtonWrapper>ACTUALIZAR</ButtonWrapper>
                            </div>
                        </Form>
                    </Formik>}
            </DialogContent>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        pago: state.pagos.pago,
        statusPago: state.pagos.status,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getLotes: () => dispatch(getLotesThunk()),
        storePago: (obj) => dispatch(storePagoThunk(obj)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PagoCreateDialog);
