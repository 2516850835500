import ProveedorActionTypes from "./proveedores.types";

const INITIAL_STATE = {
    proveedores: [],
    proveedor: null,
    status: 'initial',
};

const proveedoresReducer = (state = INITIAL_STATE, action) => {
    console.log("PL", action);
    switch (action.type) {
        case ProveedorActionTypes.CHANGE_STATUS_PROVEEDOR:
            return {...state, status: action.payload};
        case ProveedorActionTypes.GET_PROVEEDORES:
            return {...state, proveedores: action.payload};
        case ProveedorActionTypes.GET_PROVEEDOR:
            return {...state, proveedor: action.payload};
        case ProveedorActionTypes.STORE_PROVEEDOR:
            return {...state, proveedor: action.payload};
        case ProveedorActionTypes.UPDATE_PROVEEDOR:
            return {...state, proveedor: action.payload};
        case ProveedorActionTypes.DELETE_PROVEEDOR:
            return {...state, proveedor: action.payload};
        default:
            return state;
    }
};


export default proveedoresReducer;
