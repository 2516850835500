import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import {AccountBox, AddBusiness, AddCircle} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import CartSale from "./cart-sale";

const UserMobileMenu = (props) => {
    const {mobileMoreAnchorEl, mobileMenuId, isMobileMenuOpen, handleMobileMenuClose, handleProfileMenuOpen} = props;
    const navigate = useNavigate();
    return (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                {/*<CartSale/>*/}
            </MenuItem>
            <MenuItem onClick={() => navigate('/lote-create')}>
                <IconButton size="large" color="inherit">
                    <AddBusiness/>
                </IconButton>
                <p>Nuevo Lote</p>
            </MenuItem>
            <MenuItem onClick={() => navigate('/producto-create')}>
                <IconButton size="large" color="inherit">
                    <AddCircle/>
                </IconButton>
                <p>Nuevo Producto</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountBox/>
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>

    )
}
export default UserMobileMenu;
