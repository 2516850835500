import {Autocomplete, TextField} from "@mui/material";
import {useField, useFormikContext} from "formik";

const AutocompleteNoFormik = (props) => {
    const {name, options, labelValue, idValue, setItem, ...otherProps} = props;

    const handleChange = (e, newValue) => {
        console.log('1. paso')
        if (setItem) setItem(newValue);
        //setFieldValue(name, newValue[idValue]);
    };

    const configSelect = {
        ...otherProps,
        fullWidth: true,
        onChange: handleChange
    }

    return (
        <Autocomplete
            disablePortal
            options={options}
            autoHighlight
            {...configSelect}
            getOptionLabel={(option) => option[labelValue] ? option[labelValue] : ''}
            renderInput={
                (params) =>
                    <TextField {...configSelect} {...params} label={otherProps.label}/>}
        />
    )
}

export default AutocompleteNoFormik;
