import {Avatar, Card, CardActions, CardContent, CardHeader, IconButton, Tooltip, Typography} from "@mui/material";
import {format} from "date-fns";
import {
    AddCard,
    Edit,
    EventAvailable,
    EventBusy,
    Inventory2,
    MoreVert,
    Paid,
    PriceCheck,
    Widgets
} from "@mui/icons-material";
import {useState} from "react";
import PagoCreateDialog from "../pago/pago-create/pago-create-dialog";
import {loadFile} from "../../../helpers/files";
import LoteEditDialog from "../lote-edit/lote-edit-dialog";

const LoteItem = (props) => {
    const {item} = props;
    const [openPago, setOpenPago] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const onAddPago = () => {
        setOpenPago(true);
    }

    const onClosePago = async () => {
        setOpenPago(false);
    }

    const onEdit = () => {
        setOpenEdit(true);
    }

    const onCloseEdit = async () => {
        setOpenEdit(false);
    }
    return (
        <Card className="h-100 position-relative">
            <CardHeader
                avatar={
                    <Avatar aria-label="Foto producto"
                            src={loadFile(item.foto_producto)}/>
                }
                action={
                    <IconButton aria-label="settings">
                        <MoreVert/>
                    </IconButton>
                }
                title={'#Lote: ' + item.id}
                subheader={item.nombre_producto.toUpperCase()}/>
            <CardContent>
                <Typography variant="body2" color="text.secondary" className="d-flex justify-content-between my-2">
                    <Tooltip title="Fecha de registro">
                        <EventAvailable/>
                    </Tooltip>
                    <b>{format(new Date(item.fecha), 'dd/MM/yyyy')}</b>
                </Typography>
                <Typography variant="body2" color="text.secondary" className="d-flex justify-content-between my-2">
                    <Tooltip title="Cantidad adquirida">
                        <Inventory2/>
                    </Tooltip>
                    <b>{`${item.cantidad_adquisicion} ${item.medida_adquisicion_nombre}'s`}</b>
                </Typography>
                <Typography variant="body2" color="text.secondary" className="d-flex justify-content-between my-2">
                    <Tooltip title={`${item.medida_producto}'s por ${item.medida_adquisicion_nombre}`}>
                        <Widgets/>
                    </Tooltip>
                    <b>{`${item.cantidad_unidad} [${item.medida_producto}'s] por ${item.medida_adquisicion_nombre}`}</b>
                </Typography>
                {item.has_fecha_vencimiento ?
                    <Typography variant="body2" className="d-flex justify-content-between my-2 text-danger">
                        <Tooltip title="Fecha de vencimiento">
                            <EventBusy/>
                        </Tooltip>
                        <b>{format(new Date(item.fecha_vencimiento), 'dd/MM/yyyy')}</b>
                    </Typography> : null}
                <Typography variant="body2" color="text.secondary" className="d-flex justify-content-between my-2">
                    <Tooltip title="Costo total">
                        <Paid/>
                    </Tooltip>
                    <b>{item.costo}</b>
                </Typography>
                <Typography variant="body2" className="d-flex justify-content-between my-2 text-primary">
                    <Tooltip title="Cantidad cancelada">
                        <PriceCheck/>
                    </Tooltip>
                    <b>{item.cancelado}</b>
                </Typography>
                {openPago ? <PagoCreateDialog open={openPago} lote_id={item.id} onClose={onClosePago} total={item.costo} cancelado={item.cancelado}/> : null}
                {openEdit ?<LoteEditDialog open={openEdit} lote={item} onClose={onCloseEdit}/> : null}

            </CardContent>
            <CardActions disableSpacing className="justify-content-end">

                <IconButton aria-label="Editar" onClick={() => onEdit()} className="text-success">
                    <Edit/>
                </IconButton>

                {item.costo > item.cancelado ? <IconButton aria-label="Actualizar pago" onClick={() => onAddPago()}>
                    <AddCard/>
                </IconButton> : null}
            </CardActions>
        </Card>
    );
}
export default LoteItem;
