import {Component} from "react";
import LoteItem from "./lote-item";
import {getLotesThunk} from "../../../redux/lotes/lotes.thunk";
import {connect} from "react-redux";
import LoadingMsg from "../../layouts/loading";
import {TablePagination} from "@mui/material";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator, filter) {
    array = array.filter((el) => {
        const include = el.fecha.toLowerCase().includes(filter.toLowerCase());
        return filter === '' || include;
    });

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

class LoteIndex extends Component {

    state = {
        page: 0,
        rowsPerPage: 6,
        orderBy: 'fecha',
        order: 'desc',
        filter: ''
    }

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage});
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage: parseInt(event.target.value, 10), page: 0});
    };

    async componentDidMount() {
        await this.props.getLotes();
    }

    render() {
        const {lotes, status} = this.props;
        const {page, rowsPerPage, orderBy, order, filter} = this.state;

        return (
            <div className="container">
                {status === 'loading' ?
                    <LoadingMsg msg="loading..."/>
                    : <div className="row">
                        {stableSort(lotes, getComparator(order, orderBy), filter)
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((item, index) =>
                            <div className="col-md-6 col-lg-4 my-3" key={index}>
                                <LoteItem item={item}/>
                            </div>)}
                    </div>}
                <TablePagination
                    className="child-mb-0"
                    rowsPerPageOptions={[6, 10, 24]}
                    component="div"
                    count={lotes.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        lotes: state.lotes.lotes,
        status: state.lotes.status,
    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        getLotes: () => dispatch(getLotesThunk()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(LoteIndex);
