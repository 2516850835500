import axios from "axios";

export const getMedidasService = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/get-medidas`);
    return data;
}

export const getMedidaService = async (id) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/get-medida/${id}`);
    return data;
}

export const storeMedidaService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/store-medida`, obj);
    return data;
}

export const updateMedidaService = async (obj, id) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/update-medida/${id}`, obj);
    return data;
}

export const deleteMedidaService = async (id) => {
    const { data } = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/delete-medida/${id}`);
    return data;
}
