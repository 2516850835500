import {Component} from "react";
import ProductoSaleItem from "./producto-sale-item";
import {getProductosThunk} from "../../../redux/productos/productos.thunk";
import {connect} from "react-redux";
import LoadingMsg from "../../layouts/loading";
import {Checkbox, FormControl, FormControlLabel, InputLabel, Select, TablePagination} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ProductoSaleSearch from "./producto-sale-search";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator, filter, orderBy) {
    array = array.filter((el) => {
        let include;
        if (typeof el[orderBy] !== 'number') {
            if (orderBy === 'nombre') {
                include = el[orderBy]?.toLowerCase()?.includes(filter.toLowerCase());
                const includeDescripcion = el["descripcion"]?.toLowerCase()?.includes(filter.toLowerCase());
                return filter === '' || include || includeDescripcion;
            } else {
                include = el[orderBy]?.toLowerCase()?.includes(filter.toLowerCase());
                return filter === '' || include;
            }
        } else {
            const include = (el[orderBy])?.toString().includes(filter);
            return filter === '' || include;
        }
    });

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

class ProductoSaleIndex extends Component {

    state = {
        page: 0,
        rowsPerPage: 6,
        orderBy: 'nombre',
        order: 'asc',
        filter: ''
    }

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage});
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage: parseInt(event.target.value, 10), page: 0});
    };

    async componentDidMount() {
        await this.props.getProductos();
    }

    handleChangeFilter = (value) => {
        this.setState({filter: value});
    };

    render() {
        const {productos, status} = this.props;
        const {page, rowsPerPage, orderBy, order, filter} = this.state;
        return (
            <div className="container">
                <div className="" style={{zIndex: '10', borderRadius: '10px'}}>
                    <ProductoSaleSearch setFilter={this.handleChangeFilter}/>
                    <div className="row">
                        <div className="col-md-7">
                            <FormControl fullWidth className="my-3">
                                <InputLabel id="demo-simple-select-label">Filtro</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={orderBy}
                                    label="Age"
                                    onChange={(e) => this.setState({orderBy: e.target.value})}>
                                    <MenuItem value='nombre'>Nombre & Descripción</MenuItem>
                                    {/*<MenuItem value='descripcion'>Descripción</MenuItem>*/}
                                    <MenuItem value='foto'>c/s Foto</MenuItem>
                                    <MenuItem value='precio'>Precio</MenuItem>
                                    <MenuItem value='cantidad_disponible'>Cantidad</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-5 d-flex align-items-center">
                            <FormControlLabel control={<Checkbox defaultChecked
                                                                 onChange={(e) => {
                                                                     this.setState({order: e.target.checked ? 'asc' : 'desc'})
                                                                 }}/>}
                                              label={order === 'asc' ? 'ASCENDENTE' : 'DESCENDENTE'}/>
                        </div>
                    </div>
                </div>
                {status === 'loading' ?
                    <LoadingMsg msg="loading..."/>
                    : <div className="pt-4 mt-4 row">
                        {stableSort(productos, getComparator(order, orderBy), filter, orderBy)
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((item, index) =>
                                <div className="col-md-6 col-lg-4 my-3" key={index}>
                                    <ProductoSaleItem item={item}/>
                                </div>)}
                    </div>}
                <TablePagination
                    className="child-mb-0"
                    rowsPerPageOptions={[6, 10, 24]}
                    component="div"
                    count={productos.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        productos: state.productos.productos,
        status: state.productos.status,
    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        getProductos: () => dispatch(getProductosThunk()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ProductoSaleIndex);
