import MarcaActionTypes from "./marcas.types";

const INITIAL_STATE = {
    marcas: [],
    marca: null,
    status: 'initial',
};

const marcasReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MarcaActionTypes.CHANGE_STATUS_MARCA:
            return {...state, status: action.payload};
        case MarcaActionTypes.GET_MARCAS:
            return {...state, marcas: action.payload};
        case MarcaActionTypes.GET_MARCA:
            return {...state, marca: action.payload};
        case MarcaActionTypes.STORE_MARCA:
            return {...state, marca: action.payload};
        case MarcaActionTypes.UPDATE_MARCA:
            return {...state, marca: action.payload};
        case MarcaActionTypes.DELETE_MARCA:
            return {...state, marca: action.payload};
        default:
            return state;
    }
};


export default marcasReducer;
