import MedidaActionTypes from "./medidas.types";

//ACTION CREATORS

export const changeMedidaStatus = (payload) => ({
    type: MedidaActionTypes.CHANGE_STATUS_MEDIDA,
    payload,
});

export const getMedidas = (payload) => ({
    type: MedidaActionTypes.GET_MEDIDAS,
    payload,
});

export const getMedida = (payload) => ({
    type: MedidaActionTypes.GET_MEDIDA,
    payload,
});

export const storeMedida = (payload) => ({
    type: MedidaActionTypes.STORE_MEDIDA,
    payload,
});

export const updateMedida = (payload) => ({
    type: MedidaActionTypes.UPDATE_MEDIDA,
    payload,
});

export const deleteMedida = (payload) => ({
    type: MedidaActionTypes.DELETE_MEDIDA,
    payload,
});
