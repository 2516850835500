import axios from "axios";

export const getVentasService = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/get-ventas`);
    return data;
}

export const getProductosByIdsService = async (ids) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/get-productos-by-ids`,{ids});
    return data;
}

export const getVentaService = async (id) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/get-venta/${id}`);
    return data;
}

export const storeVentaService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/store-venta`, obj);
    return data;
}

export const updateVentaService = async (obj, id) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/update-venta/${id}`, obj);
    return data;
}

export const addProductosVentaService = async (obj, id) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/add-productos-venta/${id}`, obj);
    return data;
}

export const deleteVentaService = async (id) => {
    const { data } = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/delete-cliente/${id}`);
    return data;
}
