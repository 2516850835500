import {Component} from "react";
import ProveedorItem from "./proveedor-item";
import {getProveedoresThunk} from "../../../redux/proveedores/proveedores.thunk";
import {connect} from "react-redux";
import LoadingMsg from "../../layouts/loading";
import ProveedorCreateDialog from "../proveedor-create/proveedor-create-dialog";
import {Button, Checkbox, FormControl, FormControlLabel, InputLabel, Select, TablePagination} from "@mui/material";
import {Add} from "@mui/icons-material";
import ProveedorSearch from "./proveedor-search";
import MenuItem from "@mui/material/MenuItem";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator, filter, orderBy) {
    array = array.filter((el) => {
        if (typeof el[orderBy] !== 'number') {
            const include = el[orderBy]?.toLowerCase().includes(filter.toLowerCase());
            return filter === '' || include;
        } else {
            const include = el[orderBy]?.toString().includes(filter);
            return filter === '' || include;
        }
    });

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

class ProveedorIndex extends Component {

    state = {
        openProveedor: false,
        filter: '',
        page: 0,
        rowsPerPage: 6,
        orderBy: 'lista_productos',
        order: 'asc',
    }

    async componentDidMount() {
        await this.props.getProveedores();
    }

    handleChangeFilter = (value) => {
        this.setState({filter: value});
    };

    onAddProveedor = () => {
        this.setState({openProveedor: true});
    }

    onCloseProveedor = async () => {
        this.setState({openProveedor: false});
    }

    render() {
        const {proveedores, status} = this.props;
        const {page, rowsPerPage, order, orderBy, filter} = this.state;
        return (
            <div className="container">
                <h4>PROVEEDORES</h4>
                <Button variant='contained'
                        color='primary'
                        size='large'
                        onClick={() => this.onAddProveedor()}>
                    <Add/>
                    NUEVO
                </Button>

                <div className="row">
                    <div className="col-md-7">
                        <FormControl fullWidth className="my-3">
                            <InputLabel id="demo-simple-select-label">Filtro</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={orderBy}
                                label="Filtro"
                                onChange={(e) => this.setState({orderBy: e.target.value})}>
                                <MenuItem value='nombre'>Nombre</MenuItem>
                                <MenuItem value='celular'>Celular</MenuItem>
                                <MenuItem value='lista_productos'>Productos</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-5 d-flex align-items-center">
                        <FormControlLabel control={<Checkbox defaultChecked
                                                             onChange={(e) => {
                                                                 this.setState({order: e.target.checked ? 'asc' : 'desc'})}}/>}
                                          label={order === 'asc' ? 'ASCENDENTE' : 'DESCENDENTE'} />
                    </div>
                </div>

                <ProveedorSearch setFilter={this.handleChangeFilter}/>
                {status === 'loading' ?
                    <LoadingMsg msg="loading..."/>
                    : <div className="row">
                        {stableSort(proveedores, getComparator(order, orderBy), filter, orderBy)
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((item, index) =>
                            <div className="col-md-6 col-lg-4 my-3" key={index}>
                                <ProveedorItem item={item}/>
                            </div>)}
                    </div>}
                <TablePagination
                    className="child-mb-0"
                    rowsPerPageOptions={[6, 10, 24]}
                    component="div"
                    count={proveedores.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}/>
                <ProveedorCreateDialog open={this.state.openProveedor} onClose={this.onCloseProveedor}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        proveedores: state.proveedores.proveedores,
        status: state.proveedores.status,
    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        getProveedores: () => dispatch(getProveedoresThunk()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ProveedorIndex);
