const MarcaActionTypes = {
  CHANGE_STATUS_MARCA: "CHANGE_STATUS_MARCA",
  GET_MARCAS: "GET_MARCAS",
  GET_MARCA: "GET_MARCA",
  STORE_MARCA: "STORE_MARCA",
  UPDATE_MARCA: "UPDATE_MARCA",
  DELETE_MARCA: "DELETE_MARCA",
};

export default MarcaActionTypes;
