import PagoActionTypes from "./pagos.types";

const INITIAL_STATE = {
    pagos: [],
    pago: null,
    status: 'initial',
};

const pagosReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PagoActionTypes.CHANGE_STATUS_PAGO:
            return {...state, status: action.payload};
        case PagoActionTypes.GET_PAGOS:
            return {...state, pagos: action.payload};
        case PagoActionTypes.GET_PAGO:
            return {...state, pago: action.payload};
        case PagoActionTypes.STORE_PAGO:
            return {...state, pago: action.payload};
        case PagoActionTypes.UPDATE_PAGO:
            return {...state, pago: action.payload};
        case PagoActionTypes.DELETE_PAGO:
            return {...state, pago: action.payload};
        default:
            return state;
    }
};


export default pagosReducer;
