import {Autocomplete, TextField} from "@mui/material";
import {useField, useFormikContext} from "formik";

const AutocompleteFieldWrapper = (props) => {
    const {name, options, labelValue, idValue, setItem, ...otherProps} = props;
    const {setFieldValue} = useFormikContext();
    const [field, meta] = useField(name);

    const handleChange = (e, newValue) => {
        setFieldValue(name, newValue);
        console.log('1. paso')
        if (setItem) setItem(newValue);
        //setFieldValue(name, newValue[idValue]);
    };

    const configSelect = {
        ...field,
        ...otherProps,
        fullWidth: true,
        onChange: handleChange
    }

    if (meta && meta.touched && meta.error) {
        configSelect.error = true;
        configSelect.helperText = meta.error;
    }

    return (
        <Autocomplete
            disablePortal
            options={options}
            autoHighlight
            {...configSelect}
            getOptionLabel={(option) => option[labelValue] ? option[labelValue] : ''}
            renderInput={
                (params) =>
                    <TextField {...configSelect} {...params} label={otherProps.label}/>}
        />
    )
}

export default AutocompleteFieldWrapper;
