import {Card, CardContent, CardHeader} from "@mui/material";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import TextFieldWrapper from "../components/layouts/forms/text-field-wrapper";
import ButtonWrapper from "../components/layouts/forms/button-wrapper";

const Login = () => {
    const INITIAL_FROM_STATE = {
        cuenta: '',
        password: '',
    };

    const FORM_VALIDATION = Yup.object().shape({
        cuenta: Yup.string().required('La cuenta es requerida'),
        password: Yup.string().required('La contraseña es requerida'),
    });

    const onSubmit = async (values) => {
        /*await this.props.editUser(values, this.props.user.id);
        toastr.success(`${this.props.t('success')}!`, this.props.t('user.successUpdated'));*/
    }

    return (
        <div className="row">
            <div className="col-md-8 offset-md-2">
                <Card>
                    <CardHeader title="Iniciar Sesión"/>
                    {/*<CardMedia component="img"
                                       className="w-50 mx-auto"
                                       image={userImg}
                                       alt={this.props.user.firstName}/>*/}
                    <CardContent>
                        <Formik initialValues={INITIAL_FROM_STATE}
                                validationSchema={FORM_VALIDATION}
                                onSubmit={values => onSubmit(values)}>
                            <Form>
                                <div className="my-3">
                                    {/*<TextField className="w-100" id="filled-basic" label="Username" variant="outlined"
                                           disabled defaultValue={userAccount.username}/>*/}
                                    <TextFieldWrapper name="cuenta" label="Cuenta"/>
                                </div>
                                <div className="my-3">
                                    {/*<TextField className="w-100" id="filled-basic" label="Firstname" variant="outlined"
                                           disabled defaultValue={userAccount.idTokenClaims.extension_firstName}/>*/}
                                    <TextFieldWrapper name="password" label="Contraseña"/>

                                </div>
                                <div className="my-3">
                                    <ButtonWrapper>INGRESAR</ButtonWrapper>
                                </div>
                            </Form>
                        </Formik>
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}

export default Login;
